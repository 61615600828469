import Sidebar from '../nav/Sidebar';
import Code from '../Code';

export default function ServerMaintanance() {

    return (
        <>
          
            <main>
                <Sidebar>
                    <div className="md:p-4">
                        <h1 className="text-xl md:text-2xl font-bold">Footer</h1>

                        {/* footer */}
                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Basic Footer</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 py-4 text-white text-center">
                                        <p>Basic Footer</p>
                                    </footer>

                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Centered Footer</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 py-4 text-white text-center">
                                        <div class="container mx-auto">
                                            <p>Centered Footer</p>
                                        </div>
                                    </footer>
                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Sticky Footer</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="h-36 flex flex-col">
                                        <main class="flex-grow">
                                            {/* Content goes here  */}
                                        </main>
                                        <footer class="bg-gray-800 py-4 text-white text-center">
                                            <p>Sticky Footer</p>
                                        </footer>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Multi-column Footer</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 py-4 text-white">
                                        <div class="container mx-auto grid grid-cols-2 gap-4 p-2">
                                            <div>
                                                <h3 class="text-lg font-bold mb-2">Column 1</h3>
                                                <ul>
                                                    <li>Link 1</li>
                                                    <li>Link 2</li>
                                                    <li>Link 3</li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h3 class="text-lg font-bold mb-2">Column 2</h3>
                                                <ul>
                                                    <li>Link 1</li>
                                                    <li>Link 2</li>
                                                    <li>Link 3</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </footer>
                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Social Icons Footer</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 py-4 text-white text-center">
                                        <div class="flex justify-center space-x-4">
                                            <a href="#" class="text-white hover:text-gray-500">
                                                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                                    {/* Social media icon */}
                                                </svg>
                                            </a>
                                            <a href="#" class="text-white hover:text-gray-500">
                                                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                                    {/* Social media icon */}
                                                </svg>
                                            </a>
                                            <a href="#" class="text-white hover:text-gray-500">
                                                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                                    {/* Social media icon */}
                                                </svg>
                                            </a>
                                        </div>
                                    </footer>

                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Footer with Logo</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 py-4 text-white text-center">
                                        <div>
                                            <img src="logo.png" alt="Logo" class="h-8 mx-auto" />
                                            <p>Footer with Logo</p>
                                        </div>
                                    </footer>

                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Dark Footer with Light Text</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 py-4 text-white text-center">
                                        <p class="text-gray-200">Dark Footer with Light Text</p>
                                    </footer>

                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Footer with Contact Information</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 py-4 text-white">
                                        <div class="container mx-auto text-center">
                                            <p>Email: info@example.com</p>
                                            <p>Phone: 123-456-7890</p>
                                            <p>Address: 123 Main St, City</p>
                                        </div>
                                    </footer>
                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Footer with Links and Copyright</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 p-4 text-white">
                                        <div class="container mx-auto flex justify-between items-center">
                                            <ul class="flex space-x-4">
                                                <li><a href="#">Link 1</a></li>
                                                <li><a href="#">Link 2</a></li>
                                                <li><a href="#">Link 3</a></li>
                                            </ul>
                                            <p>&copy; 2023 Your Company. All rights reserved.</p>
                                        </div>
                                    </footer>
                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Footer with Links and Copyright</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 p-4 text-white">
                                        <div class="container mx-auto flex justify-between items-center">
                                            <ul class="flex space-x-4">
                                                <li><a href="#">Link 1</a></li>
                                                <li><a href="#">Link 2</a></li>
                                                <li><a href="#">Link 3</a></li>
                                            </ul>
                                            <p>&copy; 2023 Your Company. All rights reserved.</p>
                                        </div>
                                    </footer>
                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Footer with Newsletter Subscription</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 py-4 text-white">
                                        <div class="container mx-auto flex justify-center items-center">
                                            <form class="flex space-x-2">
                                                <input type="email" placeholder="Enter your email" class="px-2 py-1 border-2 border-white" />
                                                <button type="submit" class="bg-white text-gray-800 px-4 py-1">Subscribe</button>
                                            </form>
                                        </div>
                                    </footer>
                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Gradient Footer</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gradient-to-r from-blue-500 to-purple-500 py-4 text-white text-center">
                                        <p>Gradient Footer</p>
                                    </footer>
                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Footer with Different Background Color</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-indigo-700 py-4 text-white text-center">
                                        <p>Footer with Different Background Color</p>
                                    </footer>
                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Footer with Bottom Border</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="border-t border-gray-300 py-4 text-center">
                                        <p>Footer with Bottom Border</p>
                                    </footer>
                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Footer with Grid Layout</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 p-4 text-white">
                                        <div class="container mx-auto grid grid-cols-3 gap-4">
                                            <div>
                                                <h3 class="text-lg font-bold mb-2">Column 1</h3>
                                                <ul>
                                                    <li>Link 1</li>
                                                    <li>Link 2</li>
                                                    <li>Link 3</li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h3 class="text-lg font-bold mb-2">Column 2</h3>
                                                <ul>
                                                    <li>Link 1</li>
                                                    <li>Link 2</li>
                                                    <li>Link 3</li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h3 class="text-lg font-bold mb-2">Column 3</h3>
                                                <ul>
                                                    <li>Link 1</li>
                                                    <li>Link 2</li>
                                                    <li>Link 3</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </footer>
                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Footer with Social Icons and Text</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 py-4 text-white text-center">
                                        <div class="flex flex-col space-y-2">
                                            <div class="flex justify-center space-x-4">
                                                <a href="#" class="text-white hover:text-gray-500">
                                                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                                        {/* Social media icon  */}
                                                    </svg>
                                                </a>
                                                <a href="#" class="text-white hover:text-gray-500">
                                                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                                        {/* Social media icon  */}
                                                    </svg>
                                                </a>
                                                <a href="#" class="text-white hover:text-gray-500">
                                                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                                                        {/* Social media icon  */}
                                                    </svg>
                                                </a>
                                            </div>
                                            <p>&copy; 2023 Your Company. All rights reserved.</p>
                                        </div>
                                    </footer>
                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Footer with Background Image</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 py-4 text-white text-center">
                                        <div class="bg-cover bg-center py-8">
                                            <p>Footer with Background Image</p>
                                        </div>
                                    </footer>
                                </div>
                            </div>


                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Footer with Rounded Corners</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 p-4 text-white rounded-tl-xl rounded-tr-xl">
                                        <p>Footer with Rounded Corners</p>
                                    </footer>
                                </div>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Footer with Shadow</h1>
                                <div className="my-2 border bg-white p-4">
                                    <footer class="bg-gray-800 p-4 text-white shadow">
                                        <p>Footer with Shadow</p>
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </Sidebar>
            </main>
        </>
    )
}