import React from 'react'
import { Link } from 'react-router-dom'
import Topnav from './nav/Topnav'

function Home() {
  
  return (
    <div>
      <Topnav />
      <div className="px-5 mx-auto dark:bg-black">
        <div className="py-12">
          <h1 className="text-center text-xl dark:text-[#FFE332]">
            UI Tailwindcss Components
          </h1>
          <div className="flex justify-center pt-5">
           
          </div>
        </div>
      </div>

    </div>
  )
}

export default Home