import Sidebar from '../nav/Sidebar';
import Code from '../Code';

export default function Reviews() {

    return (
        <>    
            <main>
                <Sidebar>
                    <div className="md:p-4">
                        <h1 className="text-xl md:text-2xl font-bold">Reviews</h1>

                        
                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Simple Review Card</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded-lg shadow-md p-6">
                                        <h3 class="text-lg font-semibold">John Doe</h3>
                                        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2 text-gray-600">Verified Buyer</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded-lg shadow-md p-6">
    <h3 class="text-lg font-semibold">John Doe</h3>
    <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2 text-gray-600">Verified Buyer</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Rating</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded-lg shadow-md p-6">
                                        <h3 class="text-lg font-semibold">Jane Smith</h3>
                                        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2 text-gray-600">Verified Buyer</span>
                                        </div>
                                        <div class="flex items-center mt-2">
                                            <i class="text-yellow-400 fas fa-star"></i>
                                            <i class="text-yellow-400 fas fa-star"></i>
                                            <i class="text-yellow-400 fas fa-star"></i>
                                            <i class="text-yellow-400 fas fa-star"></i>
                                            <i class="text-yellow-400 fas fa-star-half"></i>
                                            <span class="ml-2 text-gray-600">(4.5)</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded-lg shadow-md p-6">
    <h3 class="text-lg font-semibold">Jane Smith</h3>
    <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2 text-gray-600">Verified Buyer</span>
    </div>
    <div class="flex items-center mt-2">
        <i class="text-yellow-400 fas fa-star"></i>
        <i class="text-yellow-400 fas fa-star"></i>
        <i class="text-yellow-400 fas fa-star"></i>
        <i class="text-yellow-400 fas fa-star"></i>
        <i class="text-yellow-400 fas fa-star-half"></i>
        <span class="ml-2 text-gray-600">(4.5)</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Background Image</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="h-36 flex flex-col">
                                        <div class="bg-cover bg-center bg-gray-800 rounded-lg p-6 text-white">
                                            <h3 class="text-lg font-semibold">Sarah Johnson</h3>
                                            <p class="text-gray-300">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            <div class="flex items-center mt-4">
                                                <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full border-2 border-white" />
                                                <span class="ml-2">Verified Buyer</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="h-36 flex flex-col">
    <div class="bg-cover bg-center bg-gray-800 rounded-lg p-6 text-white">
        <h3 class="text-lg font-semibold">Sarah Johnson</h3>
        <p class="text-gray-300">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <div class="flex items-center mt-4">
            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full border-2 border-white" />
            <span class="ml-2">Verified Buyer</span>
        </div>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Icon</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded-lg shadow-md p-6">
                                        <i class="text-4xl text-blue-500 fas fa-quote-left mb-4"></i>
                                        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2 text-gray-600">Verified Buyer</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded-lg shadow-md p-6">
    <i class="text-4xl text-blue-500 fas fa-quote-left mb-4"></i>
    <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2 text-gray-600">Verified Buyer</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Call-to-Action</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded-lg shadow-md p-6">
                                        <h3 class="text-lg font-semibold">Amy Wilson</h3>
                                        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2 text-gray-600">Verified Buyer</span>
                                        </div>
                                        <a href="#" class="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md">Read More</a>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded-lg shadow-md p-6">
    <h3 class="text-lg font-semibold">Amy Wilson</h3>
    <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2 text-gray-600">Verified Buyer</span>
    </div>
    <a href="#" class="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md">Read More</a>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Badge</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded-lg shadow-md p-6">
                                        <h3 class="text-lg font-semibold">Mark Thompson</h3>
                                        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2 text-gray-600">Verified Buyer</span>
                                            <span class="ml-auto bg-green-500 text-white px-2 py-1 rounded-full text-xs">Featured</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded-lg shadow-md p-6">
    <h3 class="text-lg font-semibold">Mark Thompson</h3>
    <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2 text-gray-600">Verified Buyer</span>
        <span class="ml-auto bg-green-500 text-white px-2 py-1 rounded-full text-xs">Featured</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Thumbnail</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded-lg shadow-md p-6">
                                        <h3 class="text-lg font-semibold">Emily Roberts</h3>
                                        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/150" alt="Product Thumbnail" class="w-16 h-16 rounded" />
                                            <div class="ml-4">
                                                <span class="block text-gray-600">Product Name</span>
                                                <span class="block text-gray-400">Category</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded-lg shadow-md p-6">
    <h3 class="text-lg font-semibold">Emily Roberts</h3>
    <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/150" alt="Product Thumbnail" class="w-16 h-16 rounded" />
        <div class="ml-4">
            <span class="block text-gray-600">Product Name</span>
            <span class="block text-gray-400">Category</span>
        </div>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Stacked Review Cards</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded-lg shadow-md p-6">
                                        <h3 class="text-lg font-semibold">Robert Johnson</h3>
                                        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2 text-gray-600">Verified Buyer</span>
                                        </div>
                                    </div>
                                    <div class="bg-white rounded-lg shadow-md p-6 mt-4">
                                        <h3 class="text-lg font-semibold">Emma Davis</h3>
                                        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2 text-gray-600">Verified Buyer</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded-lg shadow-md p-6">
    <h3 class="text-lg font-semibold">Robert Johnson</h3>
    <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2 text-gray-600">Verified Buyer</span>
    </div>
</div>
<div class="bg-white rounded-lg shadow-md p-6 mt-4">
    <h3 class="text-lg font-semibold">Emma Davis</h3>
    <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2 text-gray-600">Verified Buyer</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Border</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="border border-gray-300 rounded-lg p-6">
                                        <h3 class="text-lg font-semibold">Olivia Wilson</h3>
                                        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2 text-gray-600">Verified Buyer</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="border border-gray-300 rounded-lg p-6">
    <h3 class="text-lg font-semibold">Olivia Wilson</h3>
    <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2 text-gray-600">Verified Buyer</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Horizontal Review Card</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded-lg shadow-md p-6 flex items-center">
                                        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-12 h-12 rounded-full" />
                                        <div class="ml-4">
                                            <h3 class="text-lg font-semibold">Sophia Taylor</h3>
                                            <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            <span class="text-gray-600">Verified Buyer</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded-lg shadow-md p-6 flex items-center">
    <img src="https://via.placeholder.com/50" alt="Avatar" class="w-12 h-12 rounded-full" />
    <div class="ml-4">
        <h3 class="text-lg font-semibold">Sophia Taylor</h3>
        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <span class="text-gray-600">Verified Buyer</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Tags</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded-lg shadow-md p-6">
                                        <h3 class="text-lg font-semibold">James Anderson</h3>
                                        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2 text-gray-600">Verified Buyer</span>
                                        </div>
                                        <div class="flex items-center mt-2">
                                            <span class="bg-blue-500 text-white px-2 py-1 rounded-full text-xs mr-2">Tag 1</span>
                                            <span class="bg-blue-500 text-white px-2 py-1 rounded-full text-xs">Tag 2</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded-lg shadow-md p-6">
    <h3 class="text-lg font-semibold">James Anderson</h3>
    <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2 text-gray-600">Verified Buyer</span>
    </div>
    <div class="flex items-center mt-2">
        <span class="bg-blue-500 text-white px-2 py-1 rounded-full text-xs mr-2">Tag 1</span>
        <span class="bg-blue-500 text-white px-2 py-1 rounded-full text-xs">Tag 2</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Avatar Border</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded-lg shadow-md p-6">
                                        <h3 class="text-lg font-semibold">Daniel Moore</h3>
                                        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full border-2 border-blue-500" />
                                            <span class="ml-2 text-gray-600">Verified Buyer</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded-lg shadow-md p-6">
    <h3 class="text-lg font-semibold">Daniel Moore</h3>
    <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full border-2 border-blue-500" />
        <span class="ml-2 text-gray-600">Verified Buyer</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Background Color</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white rounded-lg shadow-md p-6">
                                        <h3 class="text-lg font-semibold">Grace Parker</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2">Verified Buyer</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white rounded-lg shadow-md p-6">
    <h3 class="text-lg font-semibold">Grace Parker</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2">Verified Buyer</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Caption</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded-lg shadow-md p-6">
                                        <h3 class="text-lg font-semibold">William Turner</h3>
                                        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <img src="https://via.placeholder.com/350x150" alt="Product Image" class="w-full mt-4 rounded" />
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2 text-gray-600">Verified Buyer</span>
                                        </div>
                                        <p class="text-gray-500 text-sm mt-2">Caption: Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded-lg shadow-md p-6">
    <h3 class="text-lg font-semibold">William Turner</h3>
    <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <img src="https://via.placeholder.com/350x150" alt="Product Image" class="w-full mt-4 rounded" />
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2 text-gray-600">Verified Buyer</span>
    </div>
    <p class="text-gray-500 text-sm mt-2">Caption: Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Horizontal Divider</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded-lg shadow-md p-6">
                                        <h3 class="text-lg font-semibold">Liam Thompson</h3>
                                        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <hr class="my-4 border-gray-300" />
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2 text-gray-600">Verified Buyer</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded-lg shadow-md p-6">
    <h3 class="text-lg font-semibold">Liam Thompson</h3>
    <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <hr class="my-4 border-gray-300" />
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2 text-gray-600">Verified Buyer</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Background Gradient</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-gradient-to-r from-purple-500 to-indigo-500 text-white rounded-lg shadow-md p-6">
                                        <h3 class="text-lg font-semibold">Ella Johnson</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2">Verified Buyer</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-gradient-to-r from-purple-500 to-indigo-500 text-white rounded-lg shadow-md p-6">
    <h3 class="text-lg font-semibold">Ella Johnson</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2">Verified Buyer</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Overlay</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative">
                                        <img src="https://via.placeholder.com/400x200" alt="Product Image" class="w-full rounded-lg" />
                                        <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                                            <div class="text-white text-center">
                                                <h3 class="text-lg font-semibold">Lucas Wilson</h3>
                                                <p class="text-gray-300">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                <div class="flex items-center mt-4">
                                                    <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                                    <span class="ml-2 text-gray-400">Verified Buyer</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative">
    <img src="https://via.placeholder.com/400x200" alt="Product Image" class="w-full rounded-lg" />
    <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div class="text-white text-center">
            <h3 class="text-lg font-semibold">Lucas Wilson</h3>
            <p class="text-gray-300">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div class="flex items-center mt-4">
                <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                <span class="ml-2 text-gray-400">Verified Buyer</span>
            </div>
        </div>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Circle Background</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white p-6 rounded-full">
                                        <h3 class="text-lg font-semibold">Henry Moore</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2">Verified Buyer</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white p-6 rounded-full">
    <h3 class="text-lg font-semibold">Henry Moore</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2">Verified Buyer</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Review Card with Box Shadow</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded-lg shadow-xl p-6">
                                        <h3 class="text-lg font-semibold">Victoria Anderson</h3>
                                        <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <div class="flex items-center mt-4">
                                            <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
                                            <span class="ml-2 text-gray-600">Verified Buyer</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded-lg shadow-xl p-6">
    <h3 class="text-lg font-semibold">Victoria Anderson</h3>
    <p class="text-gray-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <div class="flex items-center mt-4">
        <img src="https://via.placeholder.com/50" alt="Avatar" class="w-8 h-8 rounded-full" />
        <span class="ml-2 text-gray-600">Verified Buyer</span>
    </div>
</div>`}
                                </Code>
                            </div>
                        </div>
                    </div>
                </Sidebar>
            </main>
        </>
    )
}