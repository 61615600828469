import Sidebar from '../nav/Sidebar';
import Code from '../Code';

export default function Hero() {

    return (
        <>
          
            <main>
                <Sidebar>
                    <div className="md:p-4">
                        <h1 className="text-xl md:text-2xl font-bold">Hero</h1>

                        
                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Background Image</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-cover bg-center bg-blue-500 text-white py-16 px-4">
                                        <h1 class="text-4xl font-bold mb-4">Welcome to our Website</h1>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-cover bg-center bg-blue-500 text-white py-16 px-4">
    <h1 class="text-4xl font-bold mb-4">Welcome to our Website</h1>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Overlay and Text</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative">
                                        <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-full h-auto" />
                                        <div class="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
                                            <h1 class="text-4xl font-bold text-white mb-4">Discover the Magic</h1>
                                            <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative">
    <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-full h-auto" />
    <div class="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
        <h1 class="text-4xl font-bold text-white mb-4">Discover the Magic</h1>
        <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Centered Content</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="h-36 flex flex-col">
                                        <div class="bg-gray-100 text-center py-16">
                                            <h1 class="text-4xl font-bold mb-4">Welcome to Our Website</h1>
                                            <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="h-36 flex flex-col">
    <div class="bg-gray-100 text-center py-16">
        <h1 class="text-4xl font-bold mb-4">Welcome to Our Website</h1>
        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Gradient Background</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-16 px-4">
                                        <h1 class="text-4xl font-bold mb-4">Discover the Possibilities</h1>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-gradient-to-r from-purple-500 to-indigo-500 text-white py-16 px-4">
    <h1 class="text-4xl font-bold mb-4">Discover the Possibilities</h1>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Text Overlay</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative">
                                        <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-full h-auto" />
                                        <div class="absolute inset-0 flex flex-col items-center justify-center">
                                            <h1 class="text-4xl font-bold text-white mb-4">Unleash Your Creativity</h1>
                                            <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative">
    <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-full h-auto" />
    <div class="absolute inset-0 flex flex-col items-center justify-center">
        <h1 class="text-4xl font-bold text-white mb-4">Unleash Your Creativity</h1>
        <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Call-to-Action Button</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white py-16 px-4 text-center">
                                        <h1 class="text-4xl font-bold mb-4">Join Us Today</h1>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <button class="bg-white text-blue-500 px-6 py-3 mt-8 rounded-full">Sign Up</button>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white py-16 px-4 text-center">
    <h1 class="text-4xl font-bold mb-4">Join Us Today</h1>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <button class="bg-white text-blue-500 px-6 py-3 mt-8 rounded-full">Sign Up</button>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Split Layout</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex">
                                        <div class="w-1/2 bg-blue-500 text-white py-16 px-4">
                                            <h1 class="text-4xl font-bold mb-4">Welcome to Our Website</h1>
                                            <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </div>
                                        <div class="w-1/2">
                                            <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-full h-auto" />
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex">
    <div class="w-1/2 bg-blue-500 text-white py-16 px-4">
        <h1 class="text-4xl font-bold mb-4">Welcome to Our Website</h1>
        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
    <div class="w-1/2">
        <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-full h-auto" />
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Card Overlay</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative">
                                        <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-full h-auto" />
                                        <div class="absolute inset-0 flex flex-col items-center justify-center">
                                            <div class="bg-white p-8 rounded-lg shadow-lg">
                                                <h1 class="text-4xl font-bold mb-4">Discover the Beauty</h1>
                                                <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative">
    <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-full h-auto" />
    <div class="absolute inset-0 flex flex-col items-center justify-center">
        <div class="bg-white p-8 rounded-lg shadow-lg">
            <h1 class="text-4xl font-bold mb-4">Discover the Beauty</h1>
            <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Vertical Text Overlay</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative">
                                        <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-full h-auto" />
                                        <div class="absolute inset-0 flex flex-col items-center justify-center">
                                            <h1 class="text-4xl font-bold text-white transform -rotate-90 origin-center mb-4">Welcome</h1>
                                            <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative">
    <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-full h-auto" />
    <div class="absolute inset-0 flex flex-col items-center justify-center">
        <h1 class="text-4xl font-bold text-white transform -rotate-90 origin-center mb-4">Welcome</h1>
        <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Video Background</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative">
                                        <video autoplay loop muted class="w-full">
                                            <source src="path/to/video.mp4" type="video/mp4" />
                                        </video>
                                        <div class="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
                                            <h1 class="text-4xl font-bold text-white mb-4">Watch Our Story</h1>
                                            <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative">
    <video autoplay loop muted class="w-full">
        <source src="path/to/video.mp4" type="video/mp4" />
    </video>
    <div class="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
        <h1 class="text-4xl font-bold text-white mb-4">Watch Our Story</h1>
        <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Slanted Background</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white py-16 px-4 relative overflow-hidden">
                                        <div class="absolute inset-0 bg-blue-700 transform skew-y-6"></div>
                                        <h1 class="text-4xl font-bold mb-4">Unleash Your Potential</h1>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white py-16 px-4 relative overflow-hidden">
    <div class="absolute inset-0 bg-blue-700 transform skew-y-6"></div>
    <h1 class="text-4xl font-bold mb-4">Unleash Your Potential</h1>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Circular Image</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-gray-100 text-center py-16">
                                        <img src="https://via.placeholder.com/200" alt="Avatar" class="w-32 h-32 rounded-full mx-auto mb-4" />
                                        <h1 class="text-4xl font-bold mb-4">Meet Our Team</h1>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-gray-100 text-center py-16">
    <img src="https://via.placeholder.com/200" alt="Avatar" class="w-32 h-32 rounded-full mx-auto mb-4" />
    <h1 class="text-4xl font-bold mb-4">Meet Our Team</h1>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Text Animation</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white py-16 px-4">
                                        <h1 class="text-4xl font-bold mb-4 animate-bounce">Welcome to Our Website</h1>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white py-16 px-4">
    <h1 class="text-4xl font-bold mb-4 animate-bounce">Welcome to Our Website</h1>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Box Shadow</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded-lg shadow-xl py-16 px-4 text-center">
                                        <h1 class="text-4xl font-bold mb-4">Discover the Possibilities</h1>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded-lg shadow-xl py-16 px-4 text-center">
    <h1 class="text-4xl font-bold mb-4">Discover the Possibilities</h1>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Image Overlay</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative">
                                        <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-full h-auto" />
                                        <div class="absolute inset-0 bg-black bg-opacity-50"></div>
                                        <div class="absolute inset-0 flex flex-col items-center justify-center">
                                            <h1 class="text-4xl font-bold text-white mb-4">Unleash Your Creativity</h1>
                                            <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative">
    <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-full h-auto" />
    <div class="absolute inset-0 bg-black bg-opacity-50"></div>
    <div class="absolute inset-0 flex flex-col items-center justify-center">
        <h1 class="text-4xl font-bold text-white mb-4">Unleash Your Creativity</h1>
        <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Full Width</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white py-16 px-4">
                                        <div class="container mx-auto">
                                            <h1 class="text-4xl font-bold mb-4">Welcome to Our Website</h1>
                                            <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white py-16 px-4">
    <div class="container mx-auto">
        <h1 class="text-4xl font-bold mb-4">Welcome to Our Website</h1>
        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Parallax Effect</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative overflow-hidden">
                                        <div class="parallax-bg bg-cover bg-center h-screen bg-url('https://via.placeholder.com/1920x1080')"></div>
                                        <div class="absolute inset-0 flex flex-col items-center justify-center">
                                            <h1 class="text-4xl font-bold text-white mb-4">Discover the Magic</h1>
                                            <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative overflow-hidden">
    <div class="parallax-bg bg-cover bg-center h-screen bg-url('https://via.placeholder.com/1920x1080')"></div>
    <div class="absolute inset-0 flex flex-col items-center justify-center">
        <h1 class="text-4xl font-bold text-white mb-4">Discover the Magic</h1>
        <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
</div>`}
                                </Code>
                            </div>


                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Side Image</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex">
                                        <div class="w-1/2">
                                            <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-full h-auto" />
                                        </div>
                                        <div class="w-1/2 bg-blue-500 text-white py-16 px-4">
                                            <h1 class="text-4xl font-bold mb-4">Welcome to Our Website</h1>
                                            <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex">
    <div class="w-1/2">
        <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-full h-auto" />
    </div>
    <div class="w-1/2 bg-blue-500 text-white py-16 px-4">
        <h1 class="text-4xl font-bold mb-4">Welcome to Our Website</h1>
        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Transparent Background</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-transparent text-white py-16 px-4">
                                        <h1 class="text-4xl font-bold mb-4">Discover the Beauty</h1>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-transparent text-white py-16 px-4">
    <h1 class="text-4xl font-bold mb-4">Discover the Beauty</h1>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Hero Section with Floating Elements</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white py-16 px-4 relative">
                                        <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-2/3 mx-auto h-auto" />
                                        <h1 class="text-4xl font-bold mb-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">Welcome to Our Website</h1>
                                        <p class="text-lg text-center absolute bottom-4 left-1/2 transform -translate-x-1/2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white py-16 px-4 relative">
    <img src="https://via.placeholder.com/800x400" alt="Hero Image" class="w-2/3 mx-auto h-auto" />
    <h1 class="text-4xl font-bold mb-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">Welcome to Our Website</h1>
    <p class="text-lg text-center absolute bottom-4 left-1/2 transform -translate-x-1/2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</div>`}
                                </Code>
                            </div>
                        </div>
                    </div>
                </Sidebar>
            </main>
        </>
    )
}