import Sidebar from '../nav/Sidebar';
import Code from '../Code';

export default function Contact() {

    return (
        <>
            <main>
                <Sidebar>
                    <div className="md:p-4">
                        <h1 className="text-xl md:text-2xl font-bold">Contact</h1>

                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Simple Contact Form</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 rounded-full mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <input type="text" placeholder="Name" class="w-full border rounded p-2 mb-4" />
                                            <input type="email" placeholder="Email" class="w-full border rounded p-2 mb-4" />
                                            <textarea placeholder="Message" class="w-full border rounded p-2 mb-4"></textarea>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 rounded-full mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <input type="text" placeholder="Name" class="w-full border rounded p-2 mb-4" />
        <input type="email" placeholder="Email" class="w-full border rounded p-2 mb-4" />
        <textarea placeholder="Message" class="w-full border rounded p-2 mb-4"></textarea>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Icon</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <div class="flex items-center border-b border-gray-300 py-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l4 4a1 1 0 01-1.414 1.414L11 6.414V17a1 1 0 11-2 0V6.414L5.707 8.707a1 1 0 11-1.414-1.414l4-4A1 1 0 0110 3z" clip-rule="evenodd" />
                                                </svg>
                                                <input type="text" placeholder="Name" class="w-full rounded p-2" />
                                            </div>
                                            <div class="flex items-center border-b border-gray-300 py-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm1-1a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4z" clip-rule="evenodd" />
                                                    <path fill-rule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                                                </svg>
                                                <input type="email" placeholder="Email" class="w-full rounded p-2" />
                                            </div>
                                            <textarea placeholder="Message" class="w-full rounded p-2 my-4"></textarea>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <div class="flex items-center border-b border-gray-300 py-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l4 4a1 1 0 01-1.414 1.414L11 6.414V17a1 1 0 11-2 0V6.414L5.707 8.707a1 1 0 11-1.414-1.414l4-4A1 1 0 0110 3z" clip-rule="evenodd" />
            </svg>
            <input type="text" placeholder="Name" class="w-full rounded p-2" />
        </div>
        <div class="flex items-center border-b border-gray-300 py-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm1-1a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4z" clip-rule="evenodd" />
                <path fill-rule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
            </svg>
            <input type="email" placeholder="Email" class="w-full rounded p-2" />
        </div>
        <textarea placeholder="Message" class="w-full rounded p-2 my-4"></textarea>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Background Image</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-cover bg-center bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <input type="text" placeholder="Name" class="w-full border text-white rounded p-2 mb-4 bg-transparent" />
                                            <input type="email" placeholder="Email" class="w-full border text-white rounded p-2 mb-4 bg-transparent" />
                                            <textarea placeholder="Message" class="w-full border text-white rounded p-2 mb-4 bg-transparent"></textarea>
                                            <button class="bg-white text-blue-500 rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-cover bg-center bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <input type="text" placeholder="Name" class="w-full border text-white rounded p-2 mb-4 bg-transparent" />
        <input type="email" placeholder="Email" class="w-full border text-white rounded p-2 mb-4 bg-transparent" />
        <textarea placeholder="Message" class="w-full border text-white rounded p-2 mb-4 bg-transparent"></textarea>
        <button class="bg-white text-blue-500 rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Labels and Icons</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <div class="mb-4">
                                                <label for="name" class="text-gray-600">Name</label>
                                                <div class="flex items-center border-b border-gray-300 py-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l4 4a1 1 0 01-1.414 1.414L11 6.414V17a1 1 0 11-2 0V6.414L5.707 8.707a1 1 0 11-1.414-1.414l4-4A1 1 0 0110 3z" clip-rule="evenodd" />
                                                    </svg>
                                                    <input type="text" id="name" placeholder="Enter your name" class="w-full rounded p-2" />
                                                </div>
                                            </div>
                                            <div class="mb-4">
                                                <label for="email" class="text-gray-600">Email</label>
                                                <div class="flex items-center border-b border-gray-300 py-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm1-1a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4z" clip-rule="evenodd" />
                                                        <path fill-rule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                                                    </svg>
                                                    <input type="email" id="email" placeholder="Enter your email" class="w-full rounded p-2" />
                                                </div>
                                            </div>
                                            <div class="mb-4">
                                                <label for="message" class="text-gray-600">Message</label>
                                                <textarea id="message" placeholder="Enter your message" class="w-full rounded p-2"></textarea>
                                            </div>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <div class="mb-4">
            <label for="name" class="text-gray-600">Name</label>
            <div class="flex items-center border-b border-gray-300 py-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l4 4a1 1 0 01-1.414 1.414L11 6.414V17a1 1 0 11-2 0V6.414L5.707 8.707a1 1 0 11-1.414-1.414l4-4A1 1 0 0110 3z" clip-rule="evenodd" />
                </svg>
                <input type="text" id="name" placeholder="Enter your name" class="w-full rounded p-2" />
            </div>
        </div>
        <div class="mb-4">
            <label for="email" class="text-gray-600">Email</label>
            <div class="flex items-center border-b border-gray-300 py-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm1-1a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4z" clip-rule="evenodd" />
                    <path fill-rule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                </svg>
                <input type="email" id="email" placeholder="Enter your email" class="w-full rounded p-2" />
            </div>
        </div>
        <div class="mb-4">
            <label for="message" class="text-gray-600">Message</label>
            <textarea id="message" placeholder="Enter your message" class="w-full rounded p-2"></textarea>
        </div>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Validation</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <div class="mb-4">
                                                <label for="name" class="text-gray-600">Name</label>
                                                <input type="text" id="name" placeholder="Enter your name" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <div class="mb-4">
                                                <label for="email" class="text-gray-600">Email</label>
                                                <input type="email" id="email" placeholder="Enter your email" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <div class="mb-4">
                                                <label for="message" class="text-gray-600">Message</label>
                                                <textarea id="message" placeholder="Enter your message" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500"></textarea>
                                            </div>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <div class="mb-4">
            <label for="name" class="text-gray-600">Name</label>
            <input type="text" id="name" placeholder="Enter your name" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
        </div>
        <div class="mb-4">
            <label for="email" class="text-gray-600">Email</label>
            <input type="email" id="email" placeholder="Enter your email" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
        </div>
        <div class="mb-4">
            <label for="message" class="text-gray-600">Message</label>
            <textarea id="message" placeholder="Enter your message" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500"></textarea>
        </div>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Checkbox</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <input type="text" placeholder="Name" class="w-full border rounded p-2 mb-4" />
                                            <input type="email" placeholder="Email" class="w-full border rounded p-2 mb-4" />
                                            <textarea placeholder="Message" class="w-full border rounded p-2 mb-4"></textarea>
                                            <div class="flex items-center mb-4">
                                                <input type="checkbox" id="subscribe" class="mr-2" />
                                                <label for="subscribe" class="text-gray-600">Subscribe to our newsletter</label>
                                            </div>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <input type="text" placeholder="Name" class="w-full border rounded p-2 mb-4" />
        <input type="email" placeholder="Email" class="w-full border rounded p-2 mb-4" />
        <textarea placeholder="Message" class="w-full border rounded p-2 mb-4"></textarea>
        <div class="flex items-center mb-4">
            <input type="checkbox" id="subscribe" class="mr-2" />
            <label for="subscribe" class="text-gray-600">Subscribe to our newsletter</label>
        </div>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Dropdown</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <input type="text" placeholder="Name" class="w-full border rounded p-2 mb-4" />
                                            <input type="email" placeholder="Email" class="w-full border rounded p-2 mb-4" />
                                            <textarea placeholder="Message" class="w-full border rounded p-2 mb-4"></textarea>
                                            <div class="mb-4">
                                                <label for="department" class="text-gray-600">Department</label>
                                                <select id="department" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500">
                                                    <option value="sales">Sales</option>
                                                    <option value="support">Support</option>
                                                    <option value="billing">Billing</option>
                                                </select>
                                            </div>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <input type="text" placeholder="Name" class="w-full border rounded p-2 mb-4" />
        <input type="email" placeholder="Email" class="w-full border rounded p-2 mb-4" />
        <textarea placeholder="Message" class="w-full border rounded p-2 mb-4"></textarea>
        <div class="mb-4">
            <label for="department" class="text-gray-600">Department</label>
            <select id="department" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500">
                <option value="sales">Sales</option>
                <option value="support">Support</option>
                <option value="billing">Billing</option>
            </select>
        </div>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Inline Fields</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <div class="flex mb-4">
                                                <div class="w-1/2 pr-2">
                                                    <label for="first-name" class="text-gray-600">First Name</label>
                                                    <input type="text" id="first-name" placeholder="First Name" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
                                                </div>
                                                <div class="w-1/2 pl-2">
                                                    <label for="last-name" class="text-gray-600">Last Name</label>
                                                    <input type="text" id="last-name" placeholder="Last Name" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
                                                </div>
                                            </div>
                                            <div class="mb-4">
                                                <label for="email" class="text-gray-600">Email</label>
                                                <input type="email" id="email" placeholder="Email" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <div class="flex mb-4">
            <div class="w-1/2 pr-2">
                <label for="first-name" class="text-gray-600">First Name</label>
                <input type="text" id="first-name" placeholder="First Name" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
            </div>
            <div class="w-1/2 pl-2">
                <label for="last-name" class="text-gray-600">Last Name</label>
                <input type="text" id="last-name" placeholder="Last Name" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
            </div>
        </div>
        <div class="mb-4">
            <label for="email" class="text-gray-600">Email</label>
            <input type="email" id="email" placeholder="Email" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
        </div>
        <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Radio Buttons</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <input type="text" placeholder="Name" class="w-full border border-gray-300 rounded p-2 mb-4" />
                                            <input type="email" placeholder="Email" class="w-full border border-gray-300 rounded p-2 mb-4" />
                                            <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
                                            <div class="mb-4">
                                                <label class="text-gray-600">Preferred Contact Method</label>
                                                <div class="flex items-center">
                                                    <input type="radio" id="contact-email" name="contact-method" class="mr-2" />
                                                    <label for="contact-email">Email</label>
                                                </div>
                                                <div class="flex items-center">
                                                    <input type="radio" id="contact-phone" name="contact-method" class="mr-2" />
                                                    <label for="contact-phone">Phone</label>
                                                </div>
                                                <div class="flex items-center">
                                                    <input type="radio" id="contact-none" name="contact-method" class="mr-2" />
                                                    <label for="contact-none">None</label>
                                                </div>
                                            </div>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <input type="text" placeholder="Name" class="w-full border border-gray-300 rounded p-2 mb-4" />
        <input type="email" placeholder="Email" class="w-full border border-gray-300 rounded p-2 mb-4" />
        <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
        <div class="mb-4">
            <label class="text-gray-600">Preferred Contact Method</label>
            <div class="flex items-center">
                <input type="radio" id="contact-email" name="contact-method" class="mr-2" />
                <label for="contact-email">Email</label>
            </div>
            <div class="flex items-center">
                <input type="radio" id="contact-phone" name="contact-method" class="mr-2" />
                <label for="contact-phone">Phone</label>
            </div>
            <div class="flex items-center">
                <input type="radio" id="contact-none" name="contact-method" class="mr-2" />
                <label for="contact-none">None</label>
            </div>
        </div>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Inline Icons</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <div class="flex mb-4">
                                                <div class="flex items-center border-b border-gray-300 py-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l4 4a1 1 0 01-1.414 1.414L11 6.414V17a1 1 0 11-2 0V6.414L5.707 8.707a1 1 0 11-1.414-1.414l4-4A1 1 0 0110 3z" clip-rule="evenodd" />
                                                    </svg>
                                                    <input type="text" placeholder="Name" class="w-full rounded p-2" />
                                                </div>
                                                <div class="flex items-center border-b border-gray-300 py-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm1-1a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4z" clip-rule="evenodd" />
                                                        <path fill-rule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                                                    </svg>
                                                    <input type="email" placeholder="Email" class="w-full rounded p-2" />
                                                </div>
                                            </div>
                                            <textarea placeholder="Message" class="w-full border-b border-gray-300 rounded p-2 mb-4"></textarea>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <div class="flex mb-4">
            <div class="flex items-center border-b border-gray-300 py-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l4 4a1 1 0 01-1.414 1.414L11 6.414V17a1 1 0 11-2 0V6.414L5.707 8.707a1 1 0 11-1.414-1.414l4-4A1 1 0 0110 3z" clip-rule="evenodd" />
                </svg>
                <input type="text" placeholder="Name" class="w-full rounded p-2" />
            </div>
            <div class="flex items-center border-b border-gray-300 py-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm1-1a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4z" clip-rule="evenodd" />
                    <path fill-rule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                </svg>
                <input type="email" placeholder="Email" class="w-full border-b border-gray-300 rounded p-2" />
            </div>
        </div>
        <textarea placeholder="Message" class="w-full rounded p-2 mb-4"></textarea>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Multi-column Layout</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form class="grid grid-cols-2 gap-4">
                                            <div>
                                                <label for="first-name" class="text-gray-600">First Name</label>
                                                <input type="text" id="first-name" placeholder="First Name" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <div>
                                                <label for="last-name" class="text-gray-600">Last Name</label>
                                                <input type="text" id="last-name" placeholder="Last Name" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <div class="col-span-2">
                                                <label for="email" class="text-gray-600">Email</label>
                                                <input type="email" id="email" placeholder="Email" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <div class="col-span-2">
                                                <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
                                            </div>
                                            <div class="col-span-2">
                                                <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form class="grid grid-cols-2 gap-4">
        <div>
            <label for="first-name" class="text-gray-600">First Name</label>
            <input type="text" id="first-name" placeholder="First Name" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
        </div>
        <div>
            <label for="last-name" class="text-gray-600">Last Name</label>
            <input type="text" id="last-name" placeholder="Last Name" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
        </div>
        <div class="col-span-2">
            <label for="email" class="text-gray-600">Email</label>
            <input type="email" id="email" placeholder="Email" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
        </div>
        <div class="col-span-2">
            <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
        </div>
        <div class="col-span-2">
            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
        </div>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Social Media Icons</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <input type="text" placeholder="Name" class="w-full border border-gray-300 rounded p-2 mb-4" />
                                            <input type="email" placeholder="Email" class="w-full border border-gray-300 rounded p-2 mb-4" />
                                            <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
                                            <div class="flex items-center mb-4">
                                                <span class="mr-2">Connect with us:</span>
                                                <a href="#" class="text-gray-500 hover:text-blue-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                                        <path d="M10 3a7 7 0 017 7v.586l1.707 1.707A.996.996 0 0119 13.414V17a1 1 0 01-1 1h-3.586a.996.996 0 01-.707-.293L14 16.414V13a5 5 0 00-5-5V3z" />
                                                        <path d="M10 4a6 6 0 016 6v2.585l1.707 1.707A.996.996 0 0117 15.414V17a1 1 0 01-1 1h-2.586a.996.996 0 01-.707-.293L14 16.414V14a4 4 0 00-4-4V4zm2 2a4 4 0 00-4 4v2.586l1.707 1.707A.996.996 0 019 14.414V16a2 2 0 002 2h2.586a.996.996 0 01.707.293L15 17.414V16a4 4 0 00-4-4V6z" />
                                                    </svg>
                                                </a>
                                                <a href="#" class="text-gray-500 hover:text-blue-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2a10 10 0 100-20 10 10 0 000 20z" clip-rule="evenodd" />
                                                        <path d="M14.901 5.932a4.52 4.52 0 00.002 8.136c-.213.05-.432.07-.65.062l-.187.602h1.215v1H12.34l-.354 1.148h-1.34l-.353-1.148H8.505v-1h1.61l.094-.31c.01-.032.02-.063.03-.094a2.99 2.99 0 01-.74-.32l-.763.646L8.345 12H7.01l2.256-7.308h2.633l.285-.93h1.341l.285.93h1.625L14.901 5.932zm-1.55 2.418a1.519 1.519 0 11-2.721 1.367 1.519 1.519 0 012.72-1.367z" />
                                                    </svg>
                                                </a>
                                                <a href="#" class="text-gray-500 hover:text-blue-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                                        <path d="M9 2a7 7 0 00-7 7v2c0 3.866 3.134 7 7 7s7-3.134 7-7v-2a7 7 0 00-7-7zm5 9a5 5 0 11-10 0 5 5 0 0110 0zM9 6a1 1 0 00-1 1v2a1 1 0 002 0V7a1 1 0 00-1-1zm1 8a2 2 0 100-4 2 2 0 000 4z" />
                                                    </svg>
                                                </a>
                                            </div>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <input type="text" placeholder="Name" class="w-full border border-gray-300 rounded p-2 mb-4" />
        <input type="email" placeholder="Email" class="w-full border border-gray-300 rounded p-2 mb-4" />
        <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
        <div class="flex items-center mb-4">
            <span class="mr-2">Connect with us:</span>
            <a href="#" class="text-gray-500 hover:text-blue-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10 3a7 7 0 017 7v.586l1.707 1.707A.996.996 0 0119 13.414V17a1 1 0 01-1 1h-3.586a.996.996 0 01-.707-.293L14 16.414V13a5 5 0 00-5-5V3z" />
                    <path d="M10 4a6 6 0 016 6v2.585l1.707 1.707A.996.996 0 0117 15.414V17a1 1 0 01-1 1h-2.586a.996.996 0 01-.707-.293L14 16.414V14a4 4 0 00-4-4V4zm2 2a4 4 0 00-4 4v2.586l1.707 1.707A.996.996 0 019 14.414V16a2 2 0 002 2h2.586a.996.996 0 01.707.293L15 17.414V16a4 4 0 00-4-4V6z" />
                </svg>
            </a>
            <a href="#" class="text-gray-500 hover:text-blue-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2a10 10 0 100-20 10 10 0 000 20z" clip-rule="evenodd" />
                    <path d="M14.901 5.932a4.52 4.52 0 00.002 8.136c-.213.05-.432.07-.65.062l-.187.602h1.215v1H12.34l-.354 1.148h-1.34l-.353-1.148H8.505v-1h1.61l.094-.31c.01-.032.02-.063.03-.094a2.99 2.99 0 01-.74-.32l-.763.646L8.345 12H7.01l2.256-7.308h2.633l.285-.93h1.341l.285.93h1.625L14.901 5.932zm-1.55 2.418a1.519 1.519 0 11-2.721 1.367 1.519 1.519 0 012.72-1.367z" />
                </svg>
            </a>
            <a href="#" class="text-gray-500 hover:text-blue-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9 2a7 7 0 00-7 7v2c0 3.866 3.134 7 7 7s7-3.134 7-7v-2a7 7 0 00-7-7zm5 9a5 5 0 11-10 0 5 5 0 0110 0zM9 6a1 1 0 00-1 1v2a1 1 0 002 0V7a1 1 0 00-1-1zm1 8a2 2 0 100-4 2 2 0 000 4z" />
                </svg>
            </a>
        </div>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                        
                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Icon Buttons</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <input type="text" placeholder="Name" class="w-full border border-gray-300 rounded p-2 mb-4" />
                                            <input type="email" placeholder="Email" class="w-full border border-gray-300 rounded p-2 mb-4" />
                                            <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
                                            <div class="flex justify-center">
                                                <button class="bg-blue-500 text-white rounded-full h-10 w-10 flex items-center justify-center mx-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                        <path d="M10 2a8 8 0 018 8c0 1.916-.668 3.682-1.777 5.082l-2.206-2.206A4.003 4.003 0 0010 14H7v3H5v-5h5a4 4 0 10-2.89-6.826L8.618 4.07A7.965 7.965 0 0110 2z" />
                                                    </svg>
                                                </button>
                                                <button class="bg-red-500 text-white rounded-full h-10 w-10 flex items-center justify-center mx-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M10 3a7 7 0 017 7v.586l1.707 1.707A.996.996 0 0119 13.414V17a1 1 0 01-1 1h-3.586a.996.996 0 01-.707-.293L14 16.414V13a5 5 0 00-5-5V3z" clip-rule="evenodd" />
                                                        <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm1-1a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4z" clip-rule="evenodd" />
                                                    </svg>
                                                </button>
                                                <button class="bg-green-500 text-white rounded-full h-10 w-10 flex items-center justify-center mx-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2a10 10 0 100-20 10 10 0 000 20z" clip-rule="evenodd" />
                                                        <path d="M14.901 5.932a4.52 4.52 0 00.002 8.136c-.213.05-.432.07-.65.062l-.187.602h1.215v1H12.34l-.354 1.148h-1.34l-.353-1.148H8.505v-1h1.61l.094-.31c.01-.032.02-.063.03-.094a2.99 2.99 0 01-.74-.32l-.763.646L8.345 12H7.01l2.256-7.308h2.633l.285-.93h1.341l.285.93h1.625L14.901 5.932zm-1.55 2.418a1.519 1.519 0 11-2.721 1.367 1.519 1.519 0 012.72-1.367z" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <input type="text" placeholder="Name" class="w-full border border-gray-300 rounded p-2 mb-4" />
        <input type="email" placeholder="Email" class="w-full border border-gray-300 rounded p-2 mb-4" />
        <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
        <div class="flex justify-center">
            <button class="bg-blue-500 text-white rounded-full h-10 w-10 flex items-center justify-center mx-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10 2a8 8 0 018 8c0 1.916-.668 3.682-1.777 5.082l-2.206-2.206A4.003 4.003 0 0010 14H7v3H5v-5h5a4 4 0 10-2.89-6.826L8.618 4.07A7.965 7.965 0 0110 2z" />
                </svg>
            </button>
            <button class="bg-red-500 text-white rounded-full h-10 w-10 flex items-center justify-center mx-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 3a7 7 0 017 7v.586l1.707 1.707A.996.996 0 0119 13.414V17a1 1 0 01-1 1h-3.586a.996.996 0 01-.707-.293L14 16.414V13a5 5 0 00-5-5V3z" clip-rule="evenodd" />
                    <path fill-rule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm1-1a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4z" clip-rule="evenodd" />
                </svg>
            </button>
            <button class="bg-green-500 text-white rounded-full h-10 w-10 flex items-center justify-center mx-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2a10 10 0 100-20 10 10 0 000 20z" clip-rule="evenodd" />
                    <path d="M14.901 5.932a4.52 4.52 0 00.002 8.136c-.213.05-.432.07-.65.062l-.187.602h1.215v1H12.34l-.354 1.148h-1.34l-.353-1.148H8.505v-1h1.61l.094-.31c.01-.032.02-.063.03-.094a2.99 2.99 0 01-.74-.32l-.763.646L8.345 12H7.01l2.256-7.308h2.633l.285-.93h1.341l.285.93h1.625L14.901 5.932zm-1.55 2.418a1.519 1.519 0 11-2.721 1.367 1.519 1.519 0 012.72-1.367z" />
                </svg>
            </button>
        </div>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Dropdown</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <input type="text" placeholder="Name" class="w-full border border-gray-300 rounded p-2 mb-4" />
                                            <input type="email" placeholder="Email" class="w-full border border-gray-300 rounded p-2 mb-4" />
                                            <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
                                            <div class="mb-4">
                                                <label for="topic" class="text-gray-600">Topic</label>
                                                <select id="topic" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500">
                                                    <option value="" disabled selected>Select a topic</option>
                                                    <option value="1">General Inquiry</option>
                                                    <option value="2">Sales</option>
                                                    <option value="3">Support</option>
                                                    <option value="4">Feedback</option>
                                                </select>
                                            </div>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <input type="text" placeholder="Name" class="w-full border border-gray-300 rounded p-2 mb-4" />
        <input type="email" placeholder="Email" class="w-full border border-gray-300 rounded p-2 mb-4" />
        <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
        <div class="mb-4">
            <label for="topic" class="text-gray-600">Topic</label>
            <select id="topic" class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500">
                <option value="" disabled selected>Select a topic</option>
                <option value="1">General Inquiry</option>
                <option value="2">Sales</option>
                <option value="3">Support</option>
                <option value="4">Feedback</option>
            </select>
        </div>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Inline Labels</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <div class="mb-4 flex">
                                                <label for="name" class="text-gray-600 w-1/4">Name</label>
                                                <input type="text" id="name" placeholder=" " class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <div class="mb-4 flex">
                                                <label for="email" class="text-gray-600 w-1/4">Email</label>
                                                <input type="email" id="email" placeholder=" " class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <div class="mb-4 flex">
                                                <label for="message" class="text-gray-600 w-1/4">Message</label>
                                                <textarea id="message" placeholder=" " class="w-full rounded p-2 border border-gray-300"></textarea>
                                            </div>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <div class="mb-4 flex">
            <label for="name" class="text-gray-600 w-1/4">Name</label>
            <input type="text" id="name" placeholder=" " class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
        </div>
        <div class="mb-4 flex">
            <label for="email" class="text-gray-600 w-1/4">Email</label>
            <input type="email" id="email" placeholder=" " class="w-full rounded p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
        </div>
        <div class="mb-4 flex">
            <label for="message" class="text-gray-600 w-1/4">Message</label>
            <textarea id="message" placeholder=" " class="w-full rounded p-2 border border-gray-300"></textarea>
        </div>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>


                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Radio Buttons</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <input type="text" placeholder="Name" class="w-full border border-gray-300 rounded p-2 mb-4" />
                                            <input type="email" placeholder="Email" class="w-full border border-gray-300 rounded p-2 mb-4" />
                                            <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
                                            <div class="mb-4">
                                                <span class="text-gray-600">Preferred Contact Method</span>
                                                <div class="flex items-center mt-2">
                                                    <input type="radio" id="email" name="contact-method" class="mr-2" />
                                                    <label for="email" class="text-gray-600 mr-4">Email</label>
                                                    <input type="radio" id="phone" name="contact-method" class="mr-2" />
                                                    <label for="phone" class="text-gray-600">Phone</label>
                                                </div>
                                            </div>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <input type="text" placeholder="Name" class="w-full border border-gray-300 rounded p-2 mb-4" />
        <input type="email" placeholder="Email" class="w-full border border-gray-300 rounded p-2 mb-4" />
        <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
        <div class="mb-4">
            <span class="text-gray-600">Preferred Contact Method</span>
            <div class="flex items-center mt-2">
                <input type="radio" id="email" name="contact-method" class="mr-2" />
                <label for="email" class="text-gray-600 mr-4">Email</label>
                <input type="radio" id="phone" name="contact-method" class="mr-2" />
                <label for="phone" class="text-gray-600">Phone</label>
            </div>
        </div>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with File Upload</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <input type="text" placeholder="Name" class="w-full border border-gray-300 rounded p-2 mb-4" />
                                            <input type="email" placeholder="Email" class="w-full border border-gray-300 rounded p-2 mb-4" />
                                            <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
                                            <div class="mb-4">
                                                <label for="file" class="text-gray-600">Upload File</label>
                                                <input type="file" id="file" class="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
                                            </div>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <input type="text" placeholder="Name" class="w-full border border-gray-300 rounded p-2 mb-4" />
        <input type="email" placeholder="Email" class="w-full border border-gray-300 rounded p-2 mb-4" />
        <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
        <div class="mb-4">
            <label for="file" class="text-gray-600">Upload File</label>
            <input type="file" id="file" class="w-full p-2 border border-gray-300 focus:outline-none focus:border-blue-500" />
        </div>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Contact Form with Social Media Icons</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white rounded shadow p-4">
                                        <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
                                        <h3 class="text-xl font-bold mb-2">Contact Us</h3>
                                        <form>
                                            <input type="text" placeholder="Name" class="w-full border border-gray-300 rounded p-2 mb-4" />
                                            <input type="email" placeholder="Email" class="w-full border border-gray-300 rounded p-2 mb-4" />
                                            <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
                                            <div class="flex items-center justify-center mb-4">
                                                <a href="#" class="text-gray-500 hover:text-blue-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                                        <path d="M10 4a6 6 0 016 6v2.585l1.707 1.707A.996.996 0 0117 15.414V17a1 1 0 01-1 1h-3.586a.996.996 0 01-.707-.293L14 15.414V14a5.992 5.992 0 00-1.75-4.243l-.707-.707A5.992 5.992 0 0014 6V4a6 6 0 00-6-6V0H6v4a6 6 0 004.243 5.757l.707.707A5.992 5.992 0 0010 14v1.414l-1.707 1.707A.996.996 0 017 17H3a1 1 0 01-1-1v-1.586a.996.996 0 01.293-.707L3 14.586V10a6 6 0 016-6z" />
                                                    </svg>
                                                </a>
                                                <a href="#" class="text-gray-500 hover:text-blue-500 ml-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                                        <path d="M12 0H8a2 2 0 00-2 2v3.586L2.707 6.293A.996.996 0 012 5.586V2a2 2 0 012-2h4c1.102 0 2 .897 2 2v1.586a.996.996 0 01-.293.707L10 5.586V8a2 2 0 002 2h3.586l1.707 1.707A.996.996 0 0117 11.414V16a2 2 0 01-2 2h-4a2 2 0 01-2-2v-3.586l-1.707-1.707A.996.996 0 018 9.414V12H2V8h4a2 2 0 012-2V2c0-1.103-.898-2-2-2z" />
                                                    </svg>
                                                </a>
                                                <a href="#" class="text-gray-500 hover:text-blue-500 ml-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M10 0C4.476 0 0 4.476 0 10s4.476 10 10 10 10-4.476 10-10S15.524 0 10 0zm-1 14H7v-1h2v1zm2.206-2.824A2.99 2.99 0 0110 12a2.99 2.99 0 01-1.206-.824l-.918.918A4.985 4.985 0 0010 14a4.985 4.985 0 001.124-.926l-.918-.918zm1.51-2.514a.98.98 0 01-.006-.01l-1.036-1.035-.472.473A3.99 3.99 0 0010 10a3.99 3.99 0 00-.793 7.894l.473-.473-1.035-1.036a.98.98 0 01-.01-.006A2.99 2.99 0 017 10a2.99 2.99 0 012.824-3.176l-.918-.918A4.985 4.985 0 005 10a4.985 4.985 0 00.926 1.124l-.918.918A2.99 2.99 0 017 13c.256 0 .51-.03.756-.087l.473-.472 1.036 1.035a.98.98 0 01-.01.006A2.99 2.99 0 0110 16a2.99 2.99 0 012.176-.924l.918.918A4.985 4.985 0 0010 19a4.985 4.985 0 00-1.124-.926l.918-.918A2.99 2.99 0 0113 14a2.99 2.99 0 01-.924 2.176l.918.918A4.985 4.985 0 0015 14a4.985 4.985 0 00-.926-1.124l.918-.918A2.99 2.99 0 0117 10a2.99 2.99 0 01-2.176-+.996zM15 8h-1v2h-2v1h2v2h1v-2h2v-1h-2V8z" clip-rule="evenodd" />
                                                    </svg>
                                                </a>
                                            </div>
                                            <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white rounded shadow p-4">
    <img src="https://via.placeholder.com/150" alt="Contact Image" class="w-24 h-24 mx-auto mb-4" />
    <h3 class="text-xl font-bold mb-2">Contact Us</h3>
    <form>
        <input type="text" placeholder="Name" class="w-full border border-gray-300 rounded p-2 mb-4" />
        <input type="email" placeholder="Email" class="w-full border border-gray-300 rounded p-2 mb-4" />
        <textarea placeholder="Message" class="w-full border border-gray-300 rounded p-2 mb-4"></textarea>
        <div class="flex items-center justify-center mb-4">
            <a href="#" class="text-gray-500 hover:text-blue-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10 4a6 6 0 016 6v2.585l1.707 1.707A.996.996 0 0117 15.414V17a1 1 0 01-1 1h-3.586a.996.996 0 01-.707-.293L14 15.414V14a5.992 5.992 0 00-1.75-4.243l-.707-.707A5.992 5.992 0 0014 6V4a6 6 0 00-6-6V0H6v4a6 6 0 004.243 5.757l.707.707A5.992 5.992 0 0010 14v1.414l-1.707 1.707A.996.996 0 017 17H3a1 1 0 01-1-1v-1.586a.996.996 0 01.293-.707L3 14.586V10a6 6 0 016-6z" />
                </svg>
            </a>
            <a href="#" class="text-gray-500 hover:text-blue-500 ml-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M12 0H8a2 2 0 00-2 2v3.586L2.707 6.293A.996.996 0 012 5.586V2a2 2 0 012-2h4c1.102 0 2 .897 2 2v1.586a.996.996 0 01-.293.707L10 5.586V8a2 2 0 002 2h3.586l1.707 1.707A.996.996 0 0117 11.414V16a2 2 0 01-2 2h-4a2 2 0 01-2-2v-3.586l-1.707-1.707A.996.996 0 018 9.414V12H2V8h4a2 2 0 012-2V2c0-1.103-.898-2-2-2z" />
                </svg>
            </a>
            <a href="#" class="text-gray-500 hover:text-blue-500 ml-4">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 0C4.476 0 0 4.476 0 10s4.476 10 10 10 10-4.476 10-10S15.524 0 10 0zm-1 14H7v-1h2v1zm2.206-2.824A2.99 2.99 0 0110 12a2.99 2.99 0 01-1.206-.824l-.918.918A4.985 4.985 0 0010 14a4.985 4.985 0 001.124-.926l-.918-.918zm1.51-2.514a.98.98 0 01-.006-.01l-1.036-1.035-.472.473A3.99 3.99 0 0010 10a3.99 3.99 0 00-.793 7.894l.473-.473-1.035-1.036a.98.98 0 01-.01-.006A2.99 2.99 0 017 10a2.99 2.99 0 012.824-3.176l-.918-.918A4.985 4.985 0 005 10a4.985 4.985 0 00.926 1.124l-.918.918A2.99 2.99 0 017 13c.256 0 .51-.03.756-.087l.473-.472 1.036 1.035a.98.98 0 01-.01.006A2.99 2.99 0 0110 16a2.99 2.99 0 012.176-.924l.918.918A4.985 4.985 0 0010 19a4.985 4.985 0 00-1.124-.926l.918-.918A2.99 2.99 0 0113 14a2.99 2.99 0 01-.924 2.176l.918.918A4.985 4.985 0 0015 14a4.985 4.985 0 00-.926-1.124l.918-.918A2.99 2.99 0 0117 10a2.99 2.99 0 01-2.176-+.996zM15 8h-1v2h-2v1h2v2h1v-2h2v-1h-2V8z" clip-rule="evenodd" />
                </svg>
            </a>
        </div>
        <button class="bg-blue-500 text-white rounded py-2 px-4">Submit</button>
    </form>
</div>`}
                                </Code>
                            </div>

                        </div>
                    </div>
                </Sidebar>
            </main>
        </>
    )
}