import React from 'react'
import Switcher from '../../Switcher'
import { motion, useScroll } from "framer-motion";
import { Link } from 'react-router-dom'
import logo from '../../image/logo.png'

function Topnav() {
    const { scrollYProgress } = useScroll();
    return (
        <div>
            <motion.div
                className="progress-bar dark:progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <nav className={`w-full py-6 bg-gray-100 dark:bg-black sticky top-0 z-50`}>
                <div className="w-full container mx-auto flex flex-wrap items-center justify-between">
                    <nav className='pl-7'>
                        <Link to="/" className='flex'>
                            <img src={logo} className="h-10"/>
                        </Link>
                    </nav>
                    <div className="flex items-center text-sm no-underline text-gray-600 dark:text-[#FFE332] pr-6">
                        <Link className="px-4 tracking-widest uppercase text-xs" to="/">Home</Link>
                        <Link className="px-4 tracking-widest uppercase text-xs" to="/components/navbar">Docs</Link>
                        <div className="px-4"><Switcher /></div>
                    </div>
                </div>
            </nav>
        </div >
    )
}

export default Topnav
