import { Link } from 'react-router-dom';
import Topnav from './Topnav';
import logo from '../../image/logo.png'

function Sidebar({ children }) {

    const data = [
        { label: "Navbar", link: "/components/navbar" },
        { label: "Footer", link: "/components/footer" },
        // { label: "Header", link: "/components/header" },
        // { label: "Galary", link: "/components/Galary" },
        { label: "Hero", link: "/components/hero" },
        { label: "Reviews", link: "/components/reviews" },
        { label: "Testimonial", link: "/components/testimonial" },
        { label: "CTA", link: "/components/cta" },
        { label: "Contact", link: "/components/contact" },
        { label: "Login", link: "/components/login" },
        // { label: "Register", link: "/components/register" },
        // { label: "Forget Password", link: "/components/forget-password" },
        // { label: "Table", link: "/components/table" },
        // { label: "Newsletter", link: "/components/newsletter" },
        // { label: "OTP Form", link: "/components/otp-form" },
        { label: "Not Found", link: "/components/not-found" },
        // { label: "Server Error", link: "/components/server-error" },
        // { label: "Server Maintanance", link: "/components/server-maintanance" },

    ]

    return (
        <div>
            <div className="md:hidden relative min-h-screen bg-white">
                <Topnav />
                <input type="checkbox" id="menu-open" className="hidden" />
                <label for="menu-open" className="z-50 fixed right-4 bottom-4 shadow-lg bg-black rounded-full p-4 dark:bg-black text-white dark:text-[#FFE332] md:hidden">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12" />
                    </svg>
                </label>
                <aside id="sidebar" className="z-50 -translate-x-full bg-gray-100 dark:bg-black text-gray-600 dark:text-[#FFE332] md:w-64 w-3/4 space-y-6 pt-6 px-0 absolute inset-y-0 left-0 transform md:relative md:translate-x-0 transition duration-200 ease-in-out  md:flex md:flex-col md:justify-between overflow-y-auto">
                    <div className="flex flex-col">
                        <div className="mx-4">
                            <Link to="/" className='flex'>
                                <img src={logo} className="h-10" />
                            </Link>
                        </div>
                        <nav>
                            <ul className="mt-4 text-gray-300 dark:text-[#FFE332] tracking-widest uppercase text-xs">
                                <div className="pb-2">
                                    <li className='py-2 px-6'>
                                        <ul className="mt-2 space-y-2 lg:space-y-4">
                                            {data.map((e) => (
                                                <li className="relative">
                                                    <Link className="block w-full py-1" to={e.link}>
                                                        <div className={"text-gray-600 dark:text-[#FFE332]"}>
                                                            {e.label}
                                                        </div>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                </div>
                            </ul>
                        </nav>
                    </div>

                    <nav>

                    </nav>
                </aside>
                <main id="content" className="flex-1 p-6 lg:px-8">
                    <div className="max-w-7xl mx-auto">
                        {children}
                    </div>
                </main>
            </div>
            <div className="hidden md:block">
                <div className={`min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-white`}>
                    <Topnav />
                    <div id="sidebar" className={`bg-gray-100 dark:bg-black hidden md:fixed md:flex md:flex-col w-64 md:h-full z-10`}>
                        <div className="hidden lg:relative lg:block lg:flex-none">
                            <div className="absolute inset-y-0 right-0 w-[50vw] dark:hidden"></div>
                            <div className="absolute top-16 bottom-0 right-0 hidden h-12 w-px bg-gradient-to-t from-slate-800 dark:block"></div>
                            <div className="absolute top-28 bottom-0 right-0 hidden w-px bg-slate-800 dark:block"></div>
                            <div className="sticky top-[4.5rem] h-[calc(100vh)] overflow-y-auto overflow-x-hidden py-20 pl-0.5">
                                <nav className="text-base lg:text-sm w-64  xl:w-72">
                                    <ul className="mt-4 text-gray-300 dark:text-[#FFE332] tracking-widest uppercase text-xs">
                                        <div className="pb-2">
                                            <li className='py-2 px-6'>
                                                <ul className="mt-2 space-y-2 lg:space-y-4">
                                                    {data.map((e) => (
                                                        <li className="relative">
                                                            <Link className="block w-full py-1" to={e.link}>
                                                                <div className={"text-gray-600 dark:text-[#FFE332]"}>
                                                                    {e.label}
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        </div>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className={'m-1 md:pl-64'} id="mainContent">
                        <div className={'p-5'}>{children}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Sidebar