import Sidebar from '../nav/Sidebar';
import Code from '../Code';

export default function CTA() {

    return (
        <>
           
            <main>
                <Sidebar>
                    <div className="md:p-4">
                        <h1 className="text-xl md:text-2xl font-bold">Call To Actions</h1>

                        {/* footer */}
                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Simple Call to Action</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white py-16 px-4 text-center">
                                        <h2 class="text-4xl font-bold mb-4">Get Started</h2>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Learn More</button>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white py-16 px-4 text-center">
    <h2 class="text-4xl font-bold mb-4">Get Started</h2>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Learn More</button>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Image Background with Overlay</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative">
                                        <img src="https://via.placeholder.com/800x400" alt="Background Image" class="w-full h-auto" />
                                        <div class="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
                                            <h2 class="text-4xl font-bold text-white mb-4">Discover More</h2>
                                            <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Explore</button>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative">
    <img src="https://via.placeholder.com/800x400" alt="Background Image" class="w-full h-auto" />
    <div class="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
        <h2 class="text-4xl font-bold text-white mb-4">Discover More</h2>
        <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Explore</button>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Gradient Background</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-16 px-4 text-center">
                                        <h2 class="text-4xl font-bold mb-4">Join Us Today</h2>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Sign Up</button>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-16 px-4 text-center">
    <h2 class="text-4xl font-bold mb-4">Join Us Today</h2>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Sign Up</button>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Split Background with Text</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white py-16 px-4">
                                        <div class="container mx-auto flex items-center justify-between">
                                            <div class="w-1/2">
                                                <h2 class="text-4xl font-bold mb-4">Experience the Difference</h2>
                                                <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Learn More</button>
                                            </div>
                                            <div class="w-1/2">
                                                <img src="https://via.placeholder.com/400" alt="Image" class="w-full h-auto" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white py-16 px-4">
    <div class="container mx-auto flex items-center justify-between">
        <div class="w-1/2">
            <h2 class="text-4xl font-bold mb-4">Experience the Difference</h2>
            <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Learn More</button>
        </div>
        <div class="w-1/2">
            <img src="https://via.placeholder.com/400" alt="Image" class="w-full h-auto" />
        </div>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Dark Background with Centered Text</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-gray-800 text-white py-16 px-4 text-center">
                                        <h2 class="text-4xl font-bold mb-4">Unlock Your Potential</h2>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <button class="bg-white text-gray-800 px-6 py-2 rounded-full mt-8">Get Started</button>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-gray-800 text-white py-16 px-4 text-center">
    <h2 class="text-4xl font-bold mb-4">Unlock Your Potential</h2>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <button class="bg-white text-gray-800 px-6 py-2 rounded-full mt-8">Get Started</button>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Call to Action with Image and Button</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white text-center py-16 px-4">
                                        <img src="https://via.placeholder.com/400" alt="Image" class="w-full h-auto mb-4" />
                                        <h2 class="text-4xl font-bold mb-4">Transform Your Business</h2>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <button class="bg-blue-500 text-white px-6 py-2 rounded-full mt-8">Learn More</button>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white text-center py-16 px-4">
    <img src="https://via.placeholder.com/400" alt="Image" class="w-full h-auto mb-4" />
    <h2 class="text-4xl font-bold mb-4">Transform Your Business</h2>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <button class="bg-blue-500 text-white px-6 py-2 rounded-full mt-8">Learn More</button>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Rounded Call to Action</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white py-16 px-4 text-center rounded-lg">
                                        <h2 class="text-4xl font-bold mb-4">Discover the Possibilities</h2>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Explore</button>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white py-16 px-4 text-center rounded-lg">
    <h2 class="text-4xl font-bold mb-4">Discover the Possibilities</h2>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Explore</button>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Two-Column Call to Action</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white py-16 px-4">
                                        <div class="container mx-auto flex items-center justify-between">
                                            <div class="w-1/2">
                                                <h2 class="text-4xl font-bold mb-4">Experience the Difference</h2>
                                                <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Learn More</button>
                                            </div>
                                            <div class="w-1/2">
                                                <img src="https://via.placeholder.com/400" alt="Image" class="w-full h-auto" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white py-16 px-4">
    <div class="container mx-auto flex items-center justify-between">
        <div class="w-1/2">
            <h2 class="text-4xl font-bold mb-4">Experience the Difference</h2>
            <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Learn More</button>
        </div>
        <div class="w-1/2">
            <img src="https://via.placeholder.com/400" alt="Image" class="w-full h-auto" />
        </div>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Dark Call to Action with Button</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-gray-800 text-white py-16 px-4 text-center">
                                        <h2 class="text-4xl font-bold mb-4">Unlock Your Potential</h2>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <button class="bg-white text-gray-800 px-6 py-2 rounded-full mt-8">Get Started</button>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-gray-800 text-white py-16 px-4 text-center">
    <h2 class="text-4xl font-bold mb-4">Unlock Your Potential</h2>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <button class="bg-white text-gray-800 px-6 py-2 rounded-full mt-8">Get Started</button>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Gradient Call to Action</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-16 px-4 text-center">
                                        <h2 class="text-4xl font-bold mb-4">Join Us Today</h2>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Sign Up</button>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-16 px-4 text-center">
    <h2 class="text-4xl font-bold mb-4">Join Us Today</h2>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Sign Up</button>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Side Image Call to Action</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex">
                                        <div class="w-1/2">
                                            <img src="https://via.placeholder.com/800x400" alt="Image" class="w-full h-auto" />
                                        </div>
                                        <div class="w-1/2 bg-blue-500 text-white py-16 px-4">
                                            <h2 class="text-4xl font-bold mb-4">Welcome to Our Website</h2>
                                            <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Learn More</button>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex">
    <div class="w-1/2">
        <img src="https://via.placeholder.com/800x400" alt="Image" class="w-full h-auto" />
    </div>
    <div class="w-1/2 bg-blue-500 text-white py-16 px-4">
        <h2 class="text-4xl font-bold mb-4">Welcome to Our Website</h2>
        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Learn More</button>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Transparent Call to Action</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-transparent text-white py-16 px-4">
                                        <h2 class="text-4xl font-bold mb-4">Discover the Beauty</h2>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Explore</button>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-transparent text-white py-16 px-4">
    <h2 class="text-4xl font-bold mb-4">Discover the Beauty</h2>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Explore</button>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Full-Width Call to Action</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white py-16 px-4 text-center">
                                        <h2 class="text-4xl font-bold mb-4">Unlock Your Potential</h2>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Get Started</button>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white py-16 px-4 text-center">
    <h2 class="text-4xl font-bold mb-4">Unlock Your Potential</h2>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Get Started</button>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Image Background with Text Overlay</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative">
                                        <img src="https://via.placeholder.com/800x400" alt="Background Image" class="w-full h-auto" />
                                        <div class="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
                                            <h2 class="text-4xl font-bold text-white mb-4">Discover More</h2>
                                            <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Explore</button>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative">
    <img src="https://via.placeholder.com/800x400" alt="Background Image" class="w-full h-auto" />
    <div class="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center">
        <h2 class="text-4xl font-bold text-white mb-4">Discover More</h2>
        <p class="text-lg text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Explore</button>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Dark Call to Action with Centered Text</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-gray-800 text-white py-16 px-4 text-center">
                                        <h2 class="text-4xl font-bold mb-4">Experience the Difference</h2>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <button class="bg-white text-gray-800 px-6 py-2 rounded-full mt-8">Learn More</button>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-gray-800 text-white py-16 px-4 text-center">
    <h2 class="text-4xl font-bold mb-4">Experience the Difference</h2>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <button class="bg-white text-gray-800 px-6 py-2 rounded-full mt-8">Learn More</button>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Split Call to Action</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white py-16 px-4">
                                        <div class="container mx-auto flex items-center justify-between">
                                            <div class="w-1/2">
                                                <h2 class="text-4xl font-bold mb-4">Join Us Today</h2>
                                                <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Sign Up</button>
                                            </div>
                                            <div class="w-1/2">
                                                <img src="https://via.placeholder.com/400" alt="Image" class="w-full h-auto" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white py-16 px-4">
    <div class="container mx-auto flex items-center justify-between">
        <div class="w-1/2">
            <h2 class="text-4xl font-bold mb-4">Join Us Today</h2>
            <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Sign Up</button>
        </div>
        <div class="w-1/2">
            <img src="https://via.placeholder.com/400" alt="Image" class="w-full h-auto" />
        </div>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Rounded Call to Action with Image</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white py-16 px-4 text-center rounded-lg">
                                        <h2 class="text-4xl font-bold mb-4">Discover the Possibilities</h2>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Explore</button>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white py-16 px-4 text-center rounded-lg">
    <h2 class="text-4xl font-bold mb-4">Discover the Possibilities</h2>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Explore</button>
</div>`}
                                </Code>
                            </div>


                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Side Image Call to Action with Overlay</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex">
                                        <div class="w-1/2">
                                            <img src="https://via.placeholder.com/800x400" alt="Image" class="w-full h-auto" />
                                        </div>
                                        <div class="w-1/2 bg-blue-500 text-white py-16 px-4">
                                            <h2 class="text-4xl font-bold mb-4">Welcome to Our Website</h2>
                                            <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Learn More</button>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex">
    <div class="w-1/2">
        <img src="https://via.placeholder.com/800x400" alt="Image" class="w-full h-auto" />
    </div>
    <div class="w-1/2 bg-blue-500 text-white py-16 px-4">
        <h2 class="text-4xl font-bold mb-4">Welcome to Our Website</h2>
        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Learn More</button>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Gradient Call to Action with Button</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-16 px-4 text-center">
                                        <h2 class="text-4xl font-bold mb-4">Transform Your Business</h2>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Get Started</button>
                                    </div>
                                </div>
                                <Code language={'html'}>{` <div class="bg-gradient-to-r from-blue-500 to-purple-500 text-white py-16 px-4 text-center">
    <h2 class="text-4xl font-bold mb-4">Transform Your Business</h2>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Get Started</button>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Dark Transparent Call to Action</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-transparent text-white py-16 px-4">
                                        <h2 class="text-4xl font-bold mb-4">Discover the Beauty</h2>
                                        <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Explore</button>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-transparent text-white py-16 px-4">
    <h2 class="text-4xl font-bold mb-4">Discover the Beauty</h2>
    <p class="text-lg">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <button class="bg-white text-blue-500 px-6 py-2 rounded-full mt-8">Explore</button>
</div>`}
                                </Code>
                            </div>

                        </div>
                    </div>
                </Sidebar>
            </main>
        </>
    )
}