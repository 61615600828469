import Sidebar from '../nav/Sidebar';
import Code from '../Code';

export default function NotFound() {

    return (
        <>
          
            <main>
                <Sidebar>
                    <div className="md:p-4">
                        <h1 className="text-xl md:text-2xl font-bold">Not Found</h1>

                        
                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 1</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg text-gray-500">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg text-gray-500">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 2</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-gray-200">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg text-gray-500">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-gray-200">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg text-gray-500">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 3</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-blue-500 text-white">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-blue-500 text-white">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 4</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-gradient-to-r from-purple-500 to-pink-500 text-white">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-gradient-to-r from-purple-500 to-pink-500 text-white">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 5</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-gray-100">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg text-gray-600">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-gray-100">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg text-gray-600">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 6</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-yellow-400">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-yellow-400">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 7</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 8</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-gradient-to-b from-blue-500 to-purple-500 text-white">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-gradient-to-b from-blue-500 to-purple-500 text-white">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 9</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-gray-200">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg text-gray-600">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-gray-200">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg text-gray-600">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 10</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-red-500 text-white">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-red-500 text-white">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 11</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-indigo-500 text-white">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-indigo-500 text-white">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 12</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-gradient-to-r from-yellow-400 to-red-500 text-white">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-gradient-to-r from-yellow-400 to-red-500 text-white">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 13</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-gradient-to-r from-yellow-400 to-red-500 text-white">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-gradient-to-r from-yellow-400 to-red-500 text-white">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 14</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-gray-100">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg text-gray-600">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-gray-100">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg text-gray-600">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>


                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 15</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-green-500 text-white">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-green-500 text-white">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 16</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>


                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 17</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-gradient-to-b from-green-500 to-blue-500 text-white">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-gradient-to-b from-green-500 to-blue-500 text-white">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 18</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-gray-200">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg text-gray-600">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-gray-200">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg text-gray-600">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 19</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-pink-500 text-white">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-pink-500 text-white">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Type 20</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="flex flex-col items-center justify-center h-screen bg-blue-500 text-white">
                                        <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
                                        <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
                                        <p class="text-lg">Oops! The page you're looking for does not exist.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="flex flex-col items-center justify-center h-screen bg-blue-500 text-white">
    <img src="https://via.placeholder.com/400" alt="Error Image" class="mb-4" />
    <h1 class="text-4xl font-bold mb-2">404 - Page Not Found</h1>
    <p class="text-lg">Oops! The page you're looking for does not exist.</p>
</div>`}
                                </Code>
                            </div>
                        </div>
                    </div>
                </Sidebar>
            </main>
        </>
    )
}