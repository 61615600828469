import Sidebar from '../nav/Sidebar';
import Code from '../Code';

export default function Navbar() {

    return (
        <>
            <main>
                <Sidebar>
                    <div className="md:p-4">
                        <h1 className="text-xl md:text-2xl font-bold">Navbar</h1>

                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Basic Top Navigation Bar</h1>
                                <div className="my-2 border bg-white p-4">
                                    <nav class="bg-gray-800">
                                        <div class="container mx-auto px-4">
                                            <div class="flex items-center justify-between h-16">
                                                <a href="#" class="text-white text-lg font-bold">Logo</a>
                                                <div class="hidden md:flex md:space-x-4">
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <Code language={'html'}>
                                    {`<nav class="bg-gray-800">
<div class="container mx-auto px-4">
    <div class="flex items-center justify-between h-16">
        <a href="#" class="text-white text-lg font-bold">Logo</a>
        <div class="hidden md:flex md:space-x-4">
            <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
            <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
            <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
            <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
        </div>
    </div>
</div>
</nav>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Centered Top Navigation Bar</h1>
                                <div className="my-2 border bg-white p-4">
                                    <nav class="bg-gray-800">
                                        <div class="container mx-auto px-4">
                                            <div class="flex items-center justify-center h-16">
                                                <a href="#" class="text-white text-lg font-bold">Logo</a>
                                                <div class="hidden md:flex md:space-x-4">
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <Code language={'html'}>
                                    {`<nav class="bg-gray-800">
    <div class="container mx-auto px-4">
        <div class="flex items-center justify-center h-16">
            <a href="#" class="text-white text-lg font-bold">Logo</a>
            <div class="hidden md:flex md:space-x-4">
                <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
            </div>
        </div>
    </div>
</nav>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Full-width Top Navigation Bar with Dropdown Menu</h1>
                                <div className="my-2 border bg-white p-4">
                                    <nav class="bg-gray-800">
                                        <div class="container mx-auto px-4">
                                            <div class="flex items-center justify-between h-16">
                                                <a href="#" class="text-white text-lg font-bold">Logo</a>
                                                <div class="hidden md:flex md:space-x-4">
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                                                    <div class="relative">
                                                        <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                                                        <div class="absolute top-full left-0 bg-gray-800 text-gray-700 py-2 px-4 rounded hidden">
                                                            <a href="#" class="block">Service 1</a>
                                                            <a href="#" class="block">Service 2</a>
                                                            <a href="#" class="block">Service 3</a>
                                                        </div>
                                                    </div>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <Code language={'html'}>
                                    {`<nav class="bg-gray-800">
    <div class="container mx-auto px-4">
        <div class="flex items-center justify-between h-16">
            <a href="#" class="text-white text-lg font-bold">Logo</a>
            <div class="hidden md:flex md:space-x-4">
                <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                <div class="relative">
                    <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                    <div class="absolute top-full left-0 bg-gray-800 text-gray-700 py-2 px-4 rounded hidden">
                        <a href="#" class="block">Service 1</a>
                        <a href="#" class="block">Service 2</a>
                        <a href="#" class="block">Service 3</a>
                    </div>
                </div>
                <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
            </div>
        </div>
    </div>
</nav>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Split Top Navigation Bar with Logo and Links</h1>
                                <div className="my-2 border bg-white p-4">
                                    <nav class="bg-gray-800">
                                        <div class="container mx-auto px-4">
                                            <div class="flex items-center justify-between h-16">
                                                <a href="#" class="text-white text-lg font-bold">Logo</a>
                                                <div class="hidden md:flex md:space-x-4">
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                                                </div>
                                                <div class="hidden md:flex md:space-x-4">
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <Code language={'html'}>
                                    {`<nav class="bg-gray-800">
    <div class="container mx-auto px-4">
        <div class="flex items-center justify-between h-16">
            <a href="#" class="text-white text-lg font-bold">Logo</a>
            <div class="hidden md:flex md:space-x-4">
                <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
            </div>
            <div class="hidden md:flex md:space-x-4">
                <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
            </div>
        </div>
    </div>
</nav>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Top Navigation Bar with Right-aligned Links</h1>
                                <div className="my-2 border bg-white p-4">
                                    <nav class="bg-gray-800">
                                        <div class="container mx-auto px-4">
                                            <div class="flex items-center justify-between h-16">
                                                <a href="#" class="text-white text-lg font-bold">Logo</a>
                                                <div class="hidden md:flex md:space-x-4 ml-auto">
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <Code language={'html'}>
                                    {`<nav class="bg-gray-800">
    <div class="container mx-auto px-4">
        <div class="flex items-center justify-between h-16">
            <a href="#" class="text-white text-lg font-bold">Logo</a>
            <div class="hidden md:flex md:space-x-4 ml-auto">
                <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
            </div>
        </div>
    </div>
</nav>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Sticky Top Navigation Bar</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="sticky top-0 bg-gray-800">
                                        <nav class="container mx-auto px-4">
                                            <div class="flex items-center justify-between h-16">
                                                <a href="#" class="text-white text-lg font-bold">Logo</a>
                                                <div class="hidden md:flex md:space-x-4">
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                                <Code language={'html'}>
                                    {`<nav class="container mx-auto px-4">
    <div class="flex items-center justify-between h-16">
        <a href="#" class="text-white text-lg font-bold">Logo</a>
        <div class="hidden md:flex md:space-x-4">
            <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
            <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
            <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
            <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
        </div>
    </div>
</nav>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Top Navigation Bar with Dropdown Mega Menu</h1>
                                <div className="my-2 border bg-white p-4">
                                    <nav class="bg-gray-800">
                                        <div class="container mx-auto px-4">
                                            <div class="flex items-center justify-between h-16">
                                                <a href="#" class="text-white text-lg font-bold">Logo</a>
                                                <div class="hidden md:flex md:space-x-4">
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                                                    <div class="relative">
                                                        <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                                                        <div class="absolute top-full left-0 bg-gray-800 text-gray-700 py-2 px-4 rounded hidden">
                                                            <div class="grid grid-cols-4 gap-4">
                                                                <a href="#" class="block">Service 1</a>
                                                                <a href="#" class="block">Service 2</a>
                                                                <a href="#" class="block">Service 3</a>
                                                                <a href="#" class="block">Service 4</a>
                                                                <a href="#" class="block">Service 5</a>
                                                                <a href="#" class="block">Service 6</a>
                                                                <a href="#" class="block">Service 7</a>
                                                                <a href="#" class="block">Service 8</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <Code language={'html'}>
                                    {`<nav class="bg-gray-800">
    <div class="container mx-auto px-4">
        <div class="flex items-center justify-between h-16">
            <a href="#" class="text-white text-lg font-bold">Logo</a>
            <div class="hidden md:flex md:space-x-4">
                <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                <div class="relative">
                    <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                    <div class="absolute top-full left-0 bg-gray-800 text-gray-700 py-2 px-4 rounded hidden">
                        <div class="grid grid-cols-4 gap-4">
                            <a href="#" class="block">Service 1</a>
                            <a href="#" class="block">Service 2</a>
                            <a href="#" class="block">Service 3</a>
                            <a href="#" class="block">Service 4</a>
                            <a href="#" class="block">Service 5</a>
                            <a href="#" class="block">Service 6</a>
                            <a href="#" class="block">Service 7</a>
                            <a href="#" class="block">Service 8</a>
                        </div>
                    </div>
                </div>
                <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
            </div>
        </div>
    </div>
</nav>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Transparent Top Navigation Bar</h1>
                                <div className="my-2 border bg-white p-4">
                                    <nav class="bg-transparent">
                                        <div class="container mx-auto px-4">
                                            <div class="flex items-center justify-between h-16">
                                                <a href="#" class="text-gray-700 text-lg font-bold">Logo</a>
                                                <div class="hidden md:flex md:space-x-4">
                                                    <a href="#" class="px-2 text-gray-700 hover:text-gray-900">Home</a>
                                                    <a href="#" class="px-2 text-gray-700 hover:text-gray-900">About</a>
                                                    <a href="#" class="px-2 text-gray-700 hover:text-gray-900">Services</a>
                                                    <a href="#" class="px-2 text-gray-700 hover:text-gray-900">Contact</a>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <Code language={'html'}>
                                    {`<nav class="bg-transparent">
    <div class="container mx-auto px-4">
        <div class="flex items-center justify-between h-16">
            <a href="#" class="text-gray-700 text-lg font-bold">Logo</a>
            <div class="hidden md:flex md:space-x-4">
                <a href="#" class="px-2 text-gray-700 hover:text-gray-900">Home</a>
                <a href="#" class="px-2 text-gray-700 hover:text-gray-900">About</a>
                <a href="#" class="px-2 text-gray-700 hover:text-gray-900">Services</a>
                <a href="#" class="px-2 text-gray-700 hover:text-gray-900">Contact</a>
            </div>
        </div>
    </div>
</nav>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Top Navigation Bar with Search Box</h1>
                                <div className="my-2 border bg-white p-4">
                                    <nav class="bg-gray-800">
                                        <div class="container mx-auto px-4">
                                            <div class="flex items-center justify-between h-16">
                                                <a href="#" class="text-white text-lg font-bold">Logo</a>
                                                <div class="hidden md:flex md:space-x-4">
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
                                                </div>
                                                <div class="md:flex items-center space-x-4">
                                                    <input type="text" class="rounded py-1 px-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600" placeholder="Search" />
                                                    <button class="bg-blue-600 text-gray-700 rounded py-1 px-2">Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <Code language={'html'}>
                                    {`<nav class="bg-gray-800">
    <div class="container mx-auto px-4">
        <div class="flex items-center justify-between h-16">
            <a href="#" class="text-white text-lg font-bold">Logo</a>
            <div class="hidden md:flex md:space-x-4">
                <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">Contact</a>
            </div>
            <div class="md:flex items-center space-x-4">
                <input type="text" class="rounded py-1 px-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600" placeholder="Search" />
                <button class="bg-blue-600 text-gray-700 rounded py-1 px-2">Search</button>
            </div>
        </div>
    </div>
</nav>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Top Navigation Bar with Badge Notifications</h1>
                                <div className="my-2 border bg-white p-4">
                                    <nav class="bg-gray-800">
                                        <div class="container mx-auto px-4">
                                            <div class="flex items-center justify-between h-16">
                                                <a href="#" class="text-white text-lg font-bold">Logo</a>
                                                <div class="hidden md:flex md:space-x-4">
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                                                    <a href="#" class="px-2 text-white hover:text-gray-200 relative">
                                                        Contact
                                                        <span class="bg-red-500 text-gray-700 rounded-full w-4 h-4 flex items-center justify-center absolute top-0 right-0 -mt-2 -mr-2">3</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <Code language={'html'}>
                                    {`<nav class="bg-gray-800">
    <div class="container mx-auto px-4">
        <div class="flex items-center justify-between h-16">
            <a href="#" class="text-white text-lg font-bold">Logo</a>
            <div class="hidden md:flex md:space-x-4">
                <a href="#" class="px-2 text-white hover:text-gray-200">Home</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">About</a>
                <a href="#" class="px-2 text-white hover:text-gray-200">Services</a>
                <a href="#" class="px-2 text-white hover:text-gray-200 relative">
                    Contact
                    <span class="bg-red-500 text-gray-700 rounded-full w-4 h-4 flex items-center justify-center absolute top-0 right-0 -mt-2 -mr-2">3</span>
                </a>
            </div>
        </div>
    </div>
</nav>`}
                                </Code>
                            </div>
                        </div>
                    </div>
                </Sidebar>
            </main>
        </>
    )
}