import Sidebar from '../nav/Sidebar';
import Code from '../Code';

export default function Testimonial() {

    return (
        <>
         
            <main>
                <Sidebar>
                    <div className="md:p-4">
                        <h1 className="text-xl md:text-2xl font-bold">Testimonial</h1>

                        {/* footer */}
                        <div className="flex flex-wrap">
                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Basic Testimonial Card</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white shadow-md rounded p-4">
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
                                        <h3 class="text-lg font-bold">John Doe</h3>
                                        <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white shadow-md rounded p-4">
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
    <h3 class="text-lg font-bold">John Doe</h3>
    <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Background Color</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-blue-500 text-white shadow-md rounded p-4">
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
                                        <h3 class="text-lg font-bold">Jane Smith</h3>
                                        <p class="text-gray-200">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-blue-500 text-white shadow-md rounded p-4">
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
    <h3 class="text-lg font-bold">Jane Smith</h3>
    <p class="text-gray-200">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Border</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="border border-gray-300 shadow-md rounded p-4">
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
                                        <h3 class="text-lg font-bold">David Johnson</h3>
                                        <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="border border-gray-300 shadow-md rounded p-4">
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
    <h3 class="text-lg font-bold">David Johnson</h3>
    <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Testimonial Text</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white shadow-md rounded p-4">
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
                                        <h3 class="text-lg font-bold">Emily Brown</h3>
                                        <p class="text-gray-600">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit."</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white shadow-md rounded p-4">
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
    <h3 class="text-lg font-bold">Emily Brown</h3>
    <p class="text-gray-600">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit."</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Rating Stars</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white shadow-md rounded p-4">
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
                                        <h3 class="text-lg font-bold">Michael Wilson</h3>
                                        <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                        <div class="flex items-center mt-2">
                                            <span class="text-yellow-500">★</span>
                                            <span class="text-yellow-500">★</span>
                                            <span class="text-yellow-500">★</span>
                                            <span class="text-gray-400">☆</span>
                                            <span class="text-gray-400">☆</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white shadow-md rounded p-4">
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
    <h3 class="text-lg font-bold">Michael Wilson</h3>
    <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
    <div class="flex items-center mt-2">
        <span class="text-yellow-500">★</span>
        <span class="text-yellow-500">★</span>
        <span class="text-yellow-500">★</span>
        <span class="text-gray-400">☆</span>
        <span class="text-gray-400">☆</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Image Border</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white shadow-md rounded p-4">
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full border-4 border-blue-500 mb-4" />
                                        <h3 class="text-lg font-bold">Sarah Thompson</h3>
                                        <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white shadow-md rounded p-4">
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full border-4 border-blue-500 mb-4" />
    <h3 class="text-lg font-bold">Sarah Thompson</h3>
    <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Icon</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white shadow-md rounded p-4">
                                        <div class="flex items-center mb-4">
                                            <img src="https://via.placeholder.com/24" alt="Quote Icon" class="mr-2" />
                                            <h3 class="text-lg font-bold">Mark Davis</h3>
                                        </div>
                                        <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white shadow-md rounded p-4">
    <div class="flex items-center mb-4">
        <img src="https://via.placeholder.com/24" alt="Quote Icon" class="mr-2" />
        <h3 class="text-lg font-bold">Mark Davis</h3>
    </div>
    <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Border and Shadow</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="border border-gray-300 shadow-md rounded p-4">
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
                                        <h3 class="text-lg font-bold">Olivia Clark</h3>
                                        <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="border border-gray-300 shadow-md rounded p-4">
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
    <h3 class="text-lg font-bold">Olivia Clark</h3>
    <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Image Overlay</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative bg-white shadow-md rounded p-4">
                                        <img src="https://via.placeholder.com/400x200" alt="Background Image" class="w-full h-32 object-cover rounded-t" />
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full absolute bottom-0 left-0 -mb-6 ml-4 border-4 border-white" />
                                        <h3 class="text-lg font-bold mt-8">Lucas Roberts</h3>
                                        <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative bg-white shadow-md rounded p-4">
    <img src="https://via.placeholder.com/400x200" alt="Background Image" class="w-full h-32 object-cover rounded-t" />
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full absolute bottom-0 left-0 -mb-6 ml-4 border-4 border-white" />
    <h3 class="text-lg font-bold mt-8">Lucas Roberts</h3>
    <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Image Overlay and Rating Stars</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative bg-white shadow-md rounded p-4">
                                        <img src="https://via.placeholder.com/400x200" alt="Background Image" class="w-full h-32 object-cover rounded-t" />
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full absolute bottom-0 left-0 -mb-6 ml-4 border-4 border-white" />
                                        <h3 class="text-lg font-bold mt-8">Jacob Adams</h3>
                                        <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                        <div class="flex items-center mt-2">
                                            <span class="text-yellow-500">★</span>
                                            <span class="text-yellow-500">★</span>
                                            <span class="text-yellow-500">★</span>
                                            <span class="text-gray-400">☆</span>
                                            <span class="text-gray-400">☆</span>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative bg-white shadow-md rounded p-4">
    <img src="https://via.placeholder.com/400x200" alt="Background Image" class="w-full h-32 object-cover rounded-t" />
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full absolute bottom-0 left-0 -mb-6 ml-4 border-4 border-white" />
    <h3 class="text-lg font-bold mt-8">Jacob Adams</h3>
    <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
    <div class="flex items-center mt-2">
        <span class="text-yellow-500">★</span>
        <span class="text-yellow-500">★</span>
        <span class="text-yellow-500">★</span>
        <span class="text-gray-400">☆</span>
        <span class="text-gray-400">☆</span>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Rounded Corners</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white shadow-md rounded-lg p-4">
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
                                        <h3 class="text-lg font-bold">Emma Green</h3>
                                        <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white shadow-md rounded-lg p-4">
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
    <h3 class="text-lg font-bold">Emma Green</h3>
    <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Gradient Background</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-gradient-to-r from-purple-500 to-blue-500 text-white shadow-md rounded p-4">
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
                                        <h3 class="text-lg font-bold">Matthew Turner</h3>
                                        <p class="text-gray-200">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-gradient-to-r from-purple-500 to-blue-500 text-white shadow-md rounded p-4">
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
    <h3 class="text-lg font-bold">Matthew Turner</h3>
    <p class="text-gray-200">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Border Radius</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white shadow-md rounded-full p-4">
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
                                        <h3 class="text-lg font-bold">Isabella Hall</h3>
                                        <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white shadow-md rounded-full p-4">
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
    <h3 class="text-lg font-bold">Isabella Hall</h3>
    <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Box Shadow</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="bg-white shadow-xl rounded p-4">
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
                                        <h3 class="text-lg font-bold">James Baker</h3>
                                        <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="bg-white shadow-xl rounded p-4">
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
    <h3 class="text-lg font-bold">James Baker</h3>
    <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Box Shadow and Border</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="border border-gray-300 shadow-xl rounded p-4">
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
                                        <h3 class="text-lg font-bold">Sophie Taylor</h3>
                                        <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="border border-gray-300 shadow-xl rounded p-4">
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full mb-4" />
    <h3 class="text-lg font-bold">Sophie Taylor</h3>
    <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Image Overlay and Gradient Background</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative bg-gradient-to-r from-purple-500 to-blue-500 text-white shadow-md rounded p-4">
                                        <img src="https://via.placeholder.com/400x200" alt="Background Image" class="w-full h-32 object-cover rounded-t" />
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full absolute bottom-0 left-0 -mb-6 ml-4 border-4 border-white" />
                                        <h3 class="text-lg font-bold mt-8">William Harris</h3>
                                        <p class="text-gray-200">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative bg-gradient-to-r from-purple-500 to-blue-500 text-white shadow-md rounded p-4">
    <img src="https://via.placeholder.com/400x200" alt="Background Image" class="w-full h-32 object-cover rounded-t" />
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full absolute bottom-0 left-0 -mb-6 ml-4 border-4 border-white" />
    <h3 class="text-lg font-bold mt-8">William Harris</h3>
    <p class="text-gray-200">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Image Overlay and Border Radius</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative bg-white shadow-md rounded-full p-4">
                                        <img src="https://via.placeholder.com/400x200" alt="Background Image" class="w-full h-32 object-cover rounded-full" />
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full absolute bottom-0 left-0 -mb-6 ml-4 border-4 border-white" />
                                        <h3 class="text-lg font-bold mt-8">Natalie Turner</h3>
                                        <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative bg-white shadow-md rounded-full p-4">
    <img src="https://via.placeholder.com/400x200" alt="Background Image" class="w-full h-32 object-cover rounded-full" />
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full absolute bottom-0 left-0 -mb-6 ml-4 border-4 border-white" />
    <h3 class="text-lg font-bold mt-8">Natalie Turner</h3>
    <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>


                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Image Overlay and Text Overlay</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative bg-gradient-to-r from-purple-500 to-blue-500 text-white shadow-md rounded p-4">
                                        <img src="https://via.placeholder.com/400x200" alt="Background Image" class="w-full h-32 object-cover rounded-t" />
                                        <div class="absolute bottom-0 left-0 p-4">
                                            <h3 class="text-lg font-bold">Thomas Adams</h3>
                                            <p class="text-gray-200">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                        </div>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative bg-gradient-to-r from-purple-500 to-blue-500 text-white shadow-md rounded p-4">
    <img src="https://via.placeholder.com/400x200" alt="Background Image" class="w-full h-32 object-cover rounded-t" />
    <div class="absolute bottom-0 left-0 p-4">
        <h3 class="text-lg font-bold">Thomas Adams</h3>
        <p class="text-gray-200">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
    </div>
</div>`}
                                </Code>
                            </div>

                            <div className="w-full">
                                <h1 className="text-lg font-bold text-gray-900 my-2">Testimonial Card with Image Overlay and Border</h1>
                                <div className="my-2 border bg-white p-4">
                                    <div class="relative bg-white shadow-md rounded p-4">
                                        <img src="https://via.placeholder.com/400x200" alt="Background Image" class="w-full h-32 object-cover rounded-t border border-gray-300" />
                                        <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full absolute bottom-0 left-0 -mb-6 ml-4 border-4 border-white" />
                                        <h3 class="text-lg font-bold mt-8">Alexis Roberts</h3>
                                        <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
                                    </div>
                                </div>
                                <Code language={'html'}>{`<div class="relative bg-white shadow-md rounded p-4">
    <img src="https://via.placeholder.com/400x200" alt="Background Image" class="w-full h-32 object-cover rounded-t border border-gray-300" />
    <img src="https://via.placeholder.com/100" alt="User Image" class="w-12 h-12 rounded-full absolute bottom-0 left-0 -mb-6 ml-4 border-4 border-white" />
    <h3 class="text-lg font-bold mt-8">Alexis Roberts</h3>
    <p class="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et enim vel risus bibendum blandit.</p>
</div>`}
                                </Code>
                            </div>
                        </div>
                    </div>
                </Sidebar>
            </main>
        </>
    )
}