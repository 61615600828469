import React from 'react'
import Home from './components/Home'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Navbar from './components/blocks/Navbar';
import Footer from './components/blocks/Footer';
import CTA from './components/blocks/CTA';
import Contact from './components/blocks/Contact';
import Hero from './components/blocks/Hero';
import Login from './components/blocks/Login';
import NotFound from './components/blocks/NotFound';
import Reviews from './components/blocks/Reviews';
import Table from './components/blocks/Table'
import ServerError from './components/blocks/ServerError'
import ServerMaintanance from './components/blocks/ServerMaintanance'
import Testimonial from './components/blocks/Testimonial'


const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/components/navbar" element={<Navbar />} exact />
          <Route path="/components/footer" element={<Footer />} exact />
          <Route path="/components/reviews" element={<Reviews />} exact />
          <Route path="/components/contact" element={<Contact />} exact />
          <Route path="/components/cta" element={<CTA />} exact />
          <Route path="/components/hero" element={<Hero />} exact />
          <Route path="/components/login" element={<Login />} exact />
          <Route path="/components/not-found" element={<NotFound />} exact />
          <Route path="/components/server-error" element={<ServerError />} exact />
          <Route path="/components/server-maintanance" element={<ServerMaintanance />} exact />
          <Route path="/components/testimonial" element={<Testimonial />} exact />
          <Route path="/components/table" element={<Table />} exact />
        
        </Routes>
      </Router>
    </div>
  )
}
export default App
